import Modal from 'Components/Modal/modal'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    const hasPopupBeenShown = sessionStorage.getItem('popupShown')

    if (!hasPopupBeenShown) {
      const timer = setTimeout(() => {
        setIsPopupVisible(true)
        sessionStorage.setItem('popupShown', 'true')
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [])

  const closePopup = () => {
    setIsPopupVisible(false)
  }
  const setting = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
  }
  return (
    <div className="flex flex-col justify-center items-center p-4 bg-white">
      <Helmet>
        <title>Best architects in Noida | Ashirwadum Architects & Developers</title>
      </Helmet>
      {isPopupVisible && (
        <div className="fixed top-0 left-0  w-full h-full flex items-end justify-end bg-black bg-opacity-50 md:py-20 md:px-20">
          <div className="relative bg-white md:w-[25rem]   w-[100%] p-4 justify-center  items-start rounded-lg shadow-lg ">
            <button
              className="absolute top-2 right-2 bg-transparent border-none text-black text-2xl"
              onClick={closePopup}
            >
              &times;
            </button>
            <div className=" flex-col flex justify-center md:p-4">
              <p className="md:text-5xl text-xl font-bold text-black  ">Consult us !</p>
              <p className="md:text-2xl text-lg font-bold text-black pt-2">
                For Project Enquiries & Design Consultations.
              </p>
              <p className="md:text-xl text-base text-black pt-4 ">
                Planning to build a new House/Office?{' '}
              </p>
              <p className="md:text-xl text-base text-black  ">
                {' '}
                Consult our team of experienced professionals to ensure you have covered it all!
              </p>

              <p className="md:text-xl text-base font-bold text-black pt-4">
                Reach Us - 9559555999
              </p>
              <div className="flex justify-center items-center p-4">
                <Link to="/contact">
                  <button className="hover:!bg-black hover:!text-white text-xl h-16 w-40 border-4 text-black border-black">
                    Contact
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col container justify-center text-lg items-center">
        <iframe
          className="lg:h-[50vh] h-[25vh] w-full md:h-[35vh] lg:w-[90%] md:w-[90%] p-2"
          src="https://www.youtube.com/embed/8seX_Ta8-6E?autoplay=1&mute=1"
          title="YouTube video player"
        ></iframe>
        <div className="flex lg:flex-row md:flex-row flex-col  gap-10 lg:pt-20 pt-10">
          <div className="flex lg:justify-center lg:items-center lg:p-0 p-4 ">
            <LazyLoadImage
              src="https://aaraglobal.s3.ap-south-1.amazonaws.com/images/class.jpg"
              alt=""
              className=" lg:h-[50vh] md:h-[35vh] h-[25vh] lg:w-[100%] w-[100%] border-4 p-2 shadow-xl"
            />
          </div>
          <div className="flex flex-col lg:w-[38rem] md:w-[20rem] lg:px-0 md:px-0 px-4  ">
            <p className="text-4xl font-bold lg:p-2 text-black">
              Classic
              <br /> House Design
            </p>
            <p className="text-[#5d5c5b] text-xl p-2">
              A design approach that is centered to create timeless elegance & charm. Ashirwadum
              Architects & developersStudio is a leading architects & interior designers for
              classical house design .
            </p>
            <p className="p-2 text-lg text-[#878787]">
              Ashirwadum Architects & developershas established itself, as one of the best
              architects & interior designers in Noida. With a touch of enduring charm and
              gracefulness, the many classic houses designed by Ashirwadum Architects &
              developershave been widely recognized !
            </p>
            <div className="pt-10 p-2  ">
              <Link to="/housing-design">
                <button className=" text-black text-lg font-bold p-2 border-4 h-16 w-56 border-black hover:text-white hover:bg-[#262626] hover:border-none">
                  CLASSICAL HOUSES
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 pt-10 lg:pt-20">
          <div className="flex flex-col gap-3 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/home/pic1.avif"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh] border-4 shadow-xl "
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/home/pic1.avif')
              }
            />
            <p className=" font-bold text-xl text-black">MILAGROSO</p>
            <p className="text-[#878787]">
              This house stands as a testament to the timeless beauty of Spanish design, capturing
              the essence of intricate detailing and romantic allure.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/home/pic2.avif"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh] border-4 shadow-xl"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/home/pic2.avif')
              }
            />
            <p className=" font-bold text-xl text-black">ATULYAM</p>
            <p className="text-[#878787]">
              From the intricate moldings and graceful arches to the expansive landscape, Each
              element is carefully curated to exude an aura of incomparable beauty and
              sophistication.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/home/pic3.webp"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh] border-4 shadow-xl"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/home/pic3.webp')
              }
            />
            <p className=" font-bold text-xl text-black">ANTARAL</p>
            <p className="text-[#878787]">
              Embracing the beauty of simplicity, Antaral House beckons you into a world where clean
              lines and balanced proportions create an ambiance of tranquility.
            </p>
          </div>
        </div>
        <div className="flex md:flex-row flex-col gap-10 lg:pt-20 pt-10">
          <div className="flex  lg:justify-center lg:items-center  ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/home/Sketch%2BGraphic%2Bmodern.jpg"
              alt=""
              className=" lg:h-[50vh] md:h-[35vh] h-[25vh] lg:w-[100%] w-[100%] border-4 p-2 shadow-xl"
            />
          </div>
          <div className="flex flex-col  lg:w-[38rem] md:w-[35rem] w-[100%]  p-2   ">
            <p className="text-4xl font-bold p-2 text-black">
              Modern
              <br /> House Design
            </p>
            <p className="text-[#5d5c5b] text-xl p-2">
              Our modern design is characterized by clean lines, open spaces, and a neutral color
              palette. Ashirwadum Architects & developers is a leading architects & interior
              Designers for modern homes.
            </p>
            <p className="p-2 text-lg text-[#878787]">
              Ashirwadum Architects & developershas established itself, as one of the best
              architects & interior designers in Noida. Modern houses designed by us seamlessly
              blurs the boundaries between indoors and nature, ushering in a truly holistic living
              experience .
            </p>
            <div className="pt-10 p-2  ">
              <Link to="/residential-design">
                <button className=" text-black text-lg font-bold p-2 border-4 h-16 w-56 border-black hover:text-white hover:bg-[#262626] hover:border-none">
                  MORDEN HOUSES{' '}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="grid  md:!grid-cols-2 grid-cols-1 lg:!grid-cols-3 gap-10 p-2 mt-20  ">
          <div className="flex flex-col gap-3 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/home/commer001.jpg"
              alt=""
              className="lg:h-[45vh] md:h-[35vh] h-[25vh] lg:w-[100%] w-[100%] border-4 shadow-xl"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/home/commer001.jpg')
              }
            />
            <p className=" font-bold text-xl text-black">UTOPIA</p>
            <p className="text-[#878787]">
              The house has been created with a vision of a space that expands into its surrounding.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/home/simple-house.avif"
              alt=""
              className="lg:h-[45vh] md:h-[35vh] h-[25vh] lg:w-[100%] w-[100%] border-4 shadow-xl"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/home/simple-house.avif')
              }
            />
            <p className=" font-bold text-xl text-black">EUPHORIA</p>
            <p className="text-[#878787]">
              This house is a euphoria-inspired ultra-modern house with huge overhanging roofs and
              seamless views.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/home/test7.jpg"
              alt=""
              className="lg:h-[45vh] md:h-[35vh] h-[25vh] lg:w-[100%] w-[100%] border-4 shadow-xl"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/home/test7.jpg')
              }
            />
            <p className=" font-bold text-xl text-black">Lantern</p>
            <p className="text-[#878787]">
              The house incoporates a sense of transparency & illumination, into the architectural
              design.
            </p>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 md:!grid-cols-2 gap-5 lg:pt-20 pt-10">
          <div className="flex flex-col  lg:items-center ">
            <div className="flex flex-col w-[20rem] lg:gap-10 gap-3">
              <p className="text-4xl font-bold text-black">Philosophy</p>
              <p className="text-[#5d5c5b] text-lg">
                The studio works with a central idea of a timeless approach to residential design so
                that they always remain graceful and charismatic
              </p>
            </div>
          </div>
          <div className="flex flex-col text-[#878787] lg:px-0  text-lg">
            <p>Design Focused Approach - </p>
            <p>
              Through thoughtful spatial arrangements, a seamless blend of indoor and outdoor
              elements, and a deep understanding of the clients' lifestyles, we ensure that the
              houses stand as timeless masterpieces that evolve with their occupants while remaining
              rooted in the principles of impeccable design.
            </p>
            <p className="lg:pt-5 pt-3">
              Ashirwadum Architects & developershas established itself, as one of the best
              architects & interior designers in Noida . With more than decade of specialized
              experience of designing Bespoke Houses, the core expertise and interest of the studio
              lies in the design of private residences , farmhouses & office interiors .
            </p>
            <div className="flex  lg:flex-row  md:flex-row flex-col  gap-10 pt-5">
              <div className="flex lg:justify-center lg:items-center md:justify-center md:items-center">
                <Link to="/contact">
                  <button className="h-16 w-56 bg-black hover:bg-gray-500 text-white text-center justify-center font-bold items-center p-3">
                    Contact Us
                  </button>
                </Link>
              </div>
              <div className="text-[#5d5c5b] font-bold text-xl ">
                Project Enquiries
                <br /> Design Consultations{' '}
              </div>
            </div>
          </div>
        </div>
        <div className="!mt-20 lg:p-0 p-4 bg-white flex justify-center items-center shadow-xl border-4 ">
          <div className=" bg-white ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/home/landscape-1.jpg"
              alt="A beautiful landscape"
              className="w-[100%]  lg:h-[60vh] md:h-[50vh] h-[25vh] transition-transform duration-500 hover:scale-95"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/home/landscape-1.jpg')
              }
            />
          </div>
        </div>

        <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5  pt-20">
          <div className="flex flex-col  lg:items-center ">
            <div className="flex flex-col w-[20rem] gap-10 ">
              <p className="text-4xl font-bold text-black">
                Publications
                <br /> Print & Digital
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-3 text-[#878787]">
            <p className="text-[#5d5c5b] text-xl">
              Wide Media Coverage
              <br />
              International Recognition I Awards I Publications{' '}
            </p>

            <p>
              Houses & offices designed by Ashirwadum Architects & developershas been widely
              published in Indian & International media, establishing Ashirwadum Architects &
              developersof one of the leading architecture firms & top interior design company in
              Noida .
            </p>
          </div>
        </div>
        <div className="flex lg:flex-row md:!flex-row  flex-col justify-center items-center lg:pt-24 pt-10 md:p-2 w-[100%] ">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/home/book1.png"
            alt=""
            className="md:w-[50%] w-[70%] lg:h-[500px] md:h-[40vh] h-[30vh] lg:p-0 p-3  border-4 shadow-xl "
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/home/book1.png')
            }
          />
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/home/book2.jpg"
            alt=""
            className="md:w-[70%] w-[100%]  lg:h-[500px] md:h-[40vh] h-[40vh] lg:p-0 p-3  border-4 shadow-xl"
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/home/book2.jpg')
            }
          />
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5  pt-20">
          <div className="flex flex-col  lg:items-center ">
            <div className="flex flex-col w-[20rem] gap-10 ">
              <p className="text-4xl font-bold text-black">
                Design
                <br /> Expertise
              </p>
            </div>
          </div>
          <div className="flex flex-col text-[#878787]">
            <p>
              A design-centric approach to building creation wields the power to transform mere
              structures into immersive experiences. By prioritizing user needs, aesthetics, and
              emotional resonance, buildings become more than just functional spaces - they become
              sources of inspiration, comfort, and connection.
            </p>
          </div>
        </div>
        <div className="flex lg:flex-row  md:flex-row flex-col lg:pt-20 lg:gap-20 pt-10 gap-5 w-full">
          <iframe
            className="w-[100%] lg:h-[50vh] md:h-[40vh] h-[30vh]"
            src="https://www.youtube.com/embed/Rns5qo0ehiw?autoplay=1&mute=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div className="flex  flex-col  md:w-[40%]  lg:p-0 p-4 lg:pt-20 lg:pb-10 ">
            <p className="text-4xl text-center font-bold p-2 text-black">Whats Clients Say</p>
            <Slider {...setting}>
              <div className="p-2 text-lg text-[#878787] whitespace-normal">
                <p className="">
                  The work done by Ashirwadum Architects is quite commendable and they truly met all
                  my requirements. They kept the aesthetic demeanor in mind while bringing utmost
                  perfection to the project. Kudos and keep up the good work! I was planning for the
                  interior designing for my villa. I got on touch with Ashirwadum Architects &
                  developers through one of my friends. To my surprise, they pulled off a very
                  unique design while utilizing the maximum space. Highly recommended!
                </p>
                <p className="text-black text-center text-xl"> Mr. JOHAN</p>
                <p className=" text-center">Infratech Lucknow</p>
              </div>
              <div className="p-2 text-lg text-[#878787] whitespace-normal">
                <p className="">
                  The work done by Ashirwadum Architects is quite commendable and they truly met all
                  my requirements. They kept the aesthetic demeanor in mind while bringing utmost
                  perfection to the project. Kudos and keep up the good work! I was planning for the
                  interior designing for my villa. I got on touch with Ashirwadum Architects &
                  developers through one of my friends. To my surprise, they pulled off a very
                  unique design while utilizing the maximum space. Highly recommended!
                </p>
                <p className="text-black text-center text-xl"> Mr. JOSH</p>
                <p className=" text-center">Infratech Lucknow</p>
              </div>
            </Slider>
            <p className="text-[#5d5c5b]">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <strong> VIDEO | </strong>
              </Link>
              <Link to="/blog">
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 gap-4 !w-[100%] !pt-20">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/home/pic5.jpg"
            alt=""
            className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/home/pic5.jpg')
            }
          />
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/home/pic6.jpg"
            alt=""
            className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/home/pic6.jpg')
            }
          />
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/home/pic8.jpg"
            alt=""
            className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/home/pic8.jpg')
            }
          />
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/home/pic7.jpeg"
            alt=""
            className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/home/pic7.jpeg')
            }
          />
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 md:gap-5  md:pt-20">
          <div className="flex flex-col  lg:items-center lg:p-0 p-2 ">
            <div className="flex flex-col md:w-[20rem] w-[18rem] md:gap-10 gap-3">
              <p className="text-4xl font-bold text-black">
                Distinctive
                <br /> Houses
              </p>
              <p className="text-[#5d5c5b]">
                Classical & Modern Style Residential Architecture & Interior Design{' '}
              </p>
            </div>
          </div>
          <div className="flex flex-col md:gap-3  text-[#878787]  ">
            <p className=" text-lg md:p-0 p-2">
              At Ashirwadum Architects & Developers, we view a house as a profound extension of the
              personal self. It reflects your unique lifestyle through carefully curated
              architecture, interior design, and decor. Our approach seamlessly integrates classical
              and modern styles to create residences that resonate with your identity and enhance
              your well-being. Specializing in a diverse range of residential projects, we offer
              bespoke solutions for private residences, farmhouses, independent houses, and villas.
              Our designs are crafted to blend functionality with aesthetic appeal, ensuring that
              each home is a true reflection of its owner.
            </p>
            <div className="flex flex-col lg:flex-row md:flex-row lg:gap-20 md:gap-10 gap-6 pt-5  text-center lg:p-0 p-4">
              <Link to="/housing-design">
                <div className="border-4 flex justify-center items-center border-black w-44 h-14  hover:bg-black hover:text-white ">
                  CLASSICAL
                </div>
              </Link>
              <div className="border-4 flex  justify-center items-center  w-36 h-14 border-black p-4">
                MORDEN
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default Home
