import * as Yup from 'yup'

/**
 * Schema for validating user sign-up data.
 * @type {import('yup').ObjectSchema}
 */
 const ContactSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('Name is required')
    .min(2, 'Name must be at least 2 characters')
    .max(50, 'Name must be at most 50 characters'),

  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
    .required('Phone number is required'),

  project_type: Yup.string()
    .required('Project type is required')
});
/**
 * Schema for validating user sign-in data.
 * @type {import('yup').ObjectSchema}
 */
const SignInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(20, 'Password must be at most 20 characters'),
})

export { SignInSchema, ContactSchema }
