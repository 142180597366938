import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useMutation } from 'react-query'
import { postFormDataFn } from 'Services/ContactUs'
import { ContactSchema } from 'Schemas'
import Modal from '../../Components/Modal/modal'
import Loader from 'Pages/Loader/loader'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const ContactUs = () => {
  const [successMessage, setSuccessMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')

  const { mutate: postFormData } = useMutation(postFormDataFn, {
    onSuccess: (data) => {
      setLoading(false)
      if (data?.response_code === 200) {
        setSuccessMessage('Form Successfully Sent.')
        handleReset()
      }
    },
    onError: (error) => {
      setLoading(false)
      console.error('Error response:', error)
    },
  })

  const initialValues = {
    first_name: '',
    mobile: '',
    project_type: '',
  }

  const { values, handleBlur, handleChange, touched, handleSubmit, errors, handleReset } =
    useFormik({
      initialValues,
      validationSchema: ContactSchema,
      onSubmit: (values) => {
        console.log(values)
        setLoading(true)
        const formData = new FormData()
        Object.keys(values).forEach((key) => formData.append(key, values[key]))
        postFormData(formData)
      },
    })

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <div className="bg-white flex justify-center items-center">
      <Helmet>
        <title>Contact Us - Ashirwadum Architects & Developers</title>
      </Helmet>
      <div className="flex flex-col justify-center container bg-white gap-10 p-4">
        <div className="flex flex-col lg:flex-row gap-3">
          <iframe
            src="https://www.youtube.com/embed/8seX_Ta8-6E?autoplay=1&mute=1"
            alt=""
            className="lg:h-[50vh] lg:w-[60%] h-[20vh] w-[90%]"
          />
          <form
            className="flex flex-col lg:w-[30%] w-[100%] justify-center p-4"
            onSubmit={handleSubmit}
          >
            <p className="text-black font-bold md:text-4xl text-2xl">Contact Us</p>
            <p className="md:text-xl text-base text-[#5d5c5b]">
              Project Enquiries & Design Consultations{' '}
            </p>
            <div className="flex flex-col !gap-3 pt-8">
              <div className="flex flex-col gap-2 ">
                <label className="text-black">Name</label>
                <input
                  className="p-3 border-2 border-black w-[90%] text-black"
                  id="first_name"
                  type="text"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                />
                {errors.first_name && touched.first_name ? (
                  <div className="text-red-600">{errors.first_name}</div>
                ) : null}
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-black">Number</label>
                <input
                  className="p-3 border-2 border-black w-[90%] text-black"
                  id="mobile"
                  type="number"
                  name="mobile"
                  value={values.mobile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.mobile && touched.mobile ? (
                  <div className="text-red-600">{errors.mobile}</div>
                ) : null}
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-black">Project Type</label>
                <select
                  className="bg-white p-4 w-[90%] border-2 border-black text-black"
                  value={values.project_type}
                  name="project_type"
                  id="project_type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" label="Select project type" />
                  <option value="Office Design" className="text-black text-xl hover:bg-white">
                    Office Design
                  </option>
                  <option value="Home Design" className="text-black text-xl hover:bg-white">
                    Home Design
                  </option>
                  <option
                    value="Another type Project"
                    className="text-black text-xl hover:bg-white"
                  >
                    Another type Project
                  </option>
                </select>
                {errors.project_type && touched.project_type ? (
                  <div className="text-red-600">{errors.project_type}</div>
                ) : null}
              </div>
            </div>
            <button
              type="submit"
              className="text-white bg-gradient-to-r from-orange-500 to-orange-700 hover:bg-gradient-to-bl 
              focus:ring-4 focus:outline-none 
              rounded-lg text-center me-2 mb-2 mt-5 p-3 w-32 text-xl font-bold"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="flex flex-col md:flex-row gap-10">
          <div className="md:w-[70%] w-[100%] grid grid-cols-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/contact/pic1.webp"
              alt=""
              className="lg:h-[28vh] md:h-[15vh] h-[12vh] cursor-pointer"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/contact/pic1.webp')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/contact/pic2.webp"
              alt=""
              className="lg:h-[28vh] md:h-[15vh] h-[12vh] cursor-pointer"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/contact/pic2.webp')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/contact/pic3.webp"
              alt=""
              className="lg:h-[28vh] md:h-[15vh] h-[12vh] cursor-pointer"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/contact/pic3.webp')
              }
            />
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex flex-col">
              <p className="text-2xl font-bold text-[#5d5c5b]">Reach Us</p>
              <p className="lg:text-xl text-base text-[#5d5c5b]">
                Careers , Media & Work Collaborations{' '}
              </p>
            </div>
            <div className="flex flex-col text-[#5d5c5b] lg:text-xl text-base">
              <p>Phone- +91-9559555999</p>
              <p>Email- info@ashirwadum.com</p>
            </div>
            <div className="flex flex-col">
              <p className="lmd:text-2xl text-xl font-bold text-[#5d5c5b]">Office Address</p>
              <p className="lg:text-xl text-base text-[#5d5c5b]">
                C 379, Sector 18, Indira Nagar, <br /> Lucknow, Uttar Pradesh 226016
              </p>
              <p className="lg:text-xl text-base text-[#5d5c5b]">
                C-25, C Block, Sector 8, <br /> Noida, Uttar Pradesh 201301
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Show the Loader if loading is true */}
      {loading && <Loader />}

      {/* Modal Component */}
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default ContactUs
