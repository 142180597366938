import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import Modal from 'Components/Modal/modal'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const AboutUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }
  const imgs = [
    {
      id: 1,
      images: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/about/slider1.jpg',
    },
    {
      id: 2,
      images: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/about/slider2.jpg',
    },
    {
      id: 3,
      images: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/about/slider3.jpg',
    },
  ]

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
  }
  return (
    <div className="bg-white flex justify-center items-center ">
      <Helmet>
        <title>Architects & Interior designing company </title>
      </Helmet>
      <div className="flex flex-col justify-center container bg-white lg:gap-14 gap-5 p-4">
        <Slider {...settings}>
          {imgs.map((item) => (
            <div key={item.id} className="p-2 z-0">
              <LazyLoadImage
                src={item.images}
                alt={item.Title}
                className="w-full lg:h-[75vh] md:h-[50vh] h-[30vh]"
              />
            </div>
          ))}
        </Slider>
        <div className="pt-10 flex md:flex-row flex-col  justify-center  ">
          <div className="text-black text-4xl lg:w-[30%] w-[90%] flex flex-col gap-3">
            <p className="font-bold">Founders</p>
            <p className="text-black text-xl ">Meenakshi Tiwari</p>
          </div>
          <div className="flex md:w-[60%] w-[100%]   text-lg ">
            <p className="text-[#878787]">
              Ashirwadum Architects & developers are based in Lucknow and they provide services
              ranging from Urban planning, landscape designing, Housing design, Commercial design,
              residential design, institutional design and interior design.
            </p>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col gap-10 pt-5 ">
          <div className="p-2">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/about/meeting.png"
              className="p-1 border-4 shadow-2xl"
              alt=""
            />
          </div>
          <div className="flex flex-col lg:w-[30%] w-[100%] justify-end gap-8">
            <p className="text-black text-4xl font-bold">Who are we?</p>
            <p className="text-[#878787] text-lg">
              Ashirwadum architects & developers are based out in the business district of Lucknow,
              India and they started their operation in 2009. Their goal is to come up with
              customized solutions in the architecture & interior design field. The organization has
              handled numerous projects of every size and during the course of its operation it has
              achieved massive success. Furthermore, they have a team of experienced professionals
              who cater to the unique requirement of the customer and in turn offer 100%
              satisfaction to them.
            </p>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col gap-10 ">
          <div className="p-2">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/about/1.jpg"
              alt=""
            />
          </div>
          <div className="flex flex-col lg:w-[30%] w-[100%] justify-end gap-8">
            <p className="text-black text-4xl font-bold">Our Vision</p>
            <p className="text-[#878787] text-lg">
              Our vision is to deliver innovative and cost-effective architectural solutions in
              order to gain the reputation of our customers.
            </p>
          </div>
        </div>
        <div className=" flex lg:flex-row flex-col  justify-center gap-8 ">
          <div className="text-blacek text-4xl lg:w-[30%] w-[100%] flex flex-col ">
            <p className="font-bold text-black text-4xl">Our mission</p>
          </div>
          <div className="flex flex-col lg:w-[50%] w-[100%] gap-5 ">
            <p className="text-[#878787] text-lg">
              Our mission involves providing top notch and safe solutions such that all our clients
              are happy with our work. In the process, we also want to be the best architectural
              company in India.
            </p>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col lg:gap-10 gap-3">
          <div className="grid md:grid-cols-2  grid-cols-1 p-2 text-center ">
            <div className="flex flex-col  md:p-4 items-center justify-center h-full">
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/minakshi.jpeg"
                alt="Co-Founder 1"
                className="w-full lg:h-[50vh] h-[35vh] border-4 p-1 shadow-2xl "
              />
              <p className="text-xl text-[#5d5c5b] mt-2">Founder</p>
            </div>
            <div className="flex flex-col md:p-4 items-center justify-center h-full">
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/about/amittiwari.jpeg"
                alt="Co-Founder 2"
                className="w-full lg:h-[50vh] h-[35vh]  border-4 p-1 shadow-2xl"
              />
              <p className="text-xl text-[#5d5c5b] mt-2">Co-Founder</p>
            </div>
          </div>
          <div className="flex flex-col lg:w-[30%] justify-end gap-8 ">
            <p className="text-black text-4xl font-bold">Our expert team</p>
            <p className="text-[#878787] text-lg">
              We are blessed to have an experienced team who completely understands the requirement
              of our customers and further leave no stone unturned to make them happy with our work.
            </p>
          </div>
        </div>
        <div className="grid lg:grid-cols-4 grid-cols-2  gap-5 text-center  ">
          <div className=" bg-white  flex flex-col justify-center items-center">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/about/minakshi.jpeg"
              alt=""
              className="md:h-[30vh] h-[18vh] border-4 p-3"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/about/minakshi.jpeg')
              }
            />
            <p className="text-xl text-[#5d5c5b] mt-2  text-center">Senior Architect</p>
          </div>
          <div className="bg-white flex flex-col  justify-center items-center">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/about/amittiwari.jpeg"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/about/amittiwari.jpeg')
              }
              alt=""
              className="md:h-[30vh] h-[18vh] border-4 p-3"
            />
            <p className="text-xl text-[#5d5c5b] mt-2  flex flex-col  justify-center items-center text-center">
              Project Architect
            </p>
          </div>
          <div className=" bg-white">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png"
              alt=""
              className=" border-4 p-3"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png')
              }
            />
            <p className="text-xl text-[#5d5c5b] text-center  mt-2">Senior Designer</p>
          </div>
          <div className=" bg-white p-4  border-2 flex flex-col justify-center  items-center">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png"
              alt=""
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png')
              }
            />
            <p className="text-xl text-[#5d5c5b] mt-2  text-centera">Finance Exec.</p>
          </div>
        </div>
        <div className="grid lg:grid-cols-4 grid-cols-2   gap-5">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png"
            alt=""
            className="w-[100%] flex flex-col  bg-white p-4  border-2 justify-center  items-center"
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png')
            }
          />
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png"
            alt=""
            className="w-[100%] flex flex-col justify-center  bg-white p-4  border-2 items-center"
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png')
            }
          />
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png"
            alt=""
            className="w-[100%] flex flex-col justify-center  bg-white p-4  border-2 items-center"
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png')
            }
          />
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png"
            alt=""
            className="w-[100%] flex flex-col justify-center  bg-white p-4  border-2  items-center "
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/resource/img_team.png')
            }
          />
        </div>
        <div className="pt-10 flex lg:flex-row flex-col  justify-center gap-8 ">
          <div className="text-blacek text-4xl lg:w-[30%] w-[100%] flex flex-col ">
            <p className="font-bold text-black text-4xl">What do we do?</p>
          </div>
          <div className="flex flex-col lg:w-[50%] w-[100%] gap-5 ">
            <p className="text-[#878787] text-lg">
              Ashirwadum architects & developers offers different types of services to the customers
              ranging from Urban planning, landscape designing, Housing design, Commercial design,
              residential design, institutional design and interior design.
            </p>
          </div>
        </div>
        <div className="flex lg:flex-row md:!flex-row  flex-col justify-center items-center lg:pt-24 pt-10 md:p-2 w-[100%] ">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/about/book1.png"
            alt=""
            className="md:w-[50%] w-[70%] lg:h-[500px] md:h-[40vh] h-[30vh] lg:p-0 p-3  border-4 shadow-xl "
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/about/book1.png')
            }
          />
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/about/book2.jpg"
            alt=""
            className="md:w-[70%] w-[100%]  lg:h-[500px] md:h-[40vh] h-[40vh] lg:p-0 p-3  border-4 shadow-xl"
            onClick={() =>
              openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/about/book2.jpg')
            }
          />
        </div>
        <div className="pt-10 flex lg:flex-row flex-col  justify-center gap-8 ">
          <div className="text-blacek text-4xl lg:w-[40%] w-[100%] flex flex-col ">
            <p className="font-bold text-black text-4xl ">We Give You The Best</p>
          </div>
          <div className="flex flex-col lg:w-[50%] w-[100%] gap-5 ">
            <p className="text-[#878787] text-lg">
              Present all the speakers and participants in GenesisExpo`s beautiful layouts. Choose
              your favorite variant of layout and create your own. You can create also single
              speaker profile with all relevant information
            </p>
          </div>
        </div>
        <div className="flex md:flex-row flex-col container  lg:gap-24 pt-10">
          <div className="flex flex-col gap-3 text-black justify-center md:w-[35%]  w-[100%]">
            <p className=" md:text-4xl text-2xl font-bold"> </p>
            <p className=" md:text-4xl text-2xl font-bold">Modern Houses</p>
            <p className="text-xl text-[#5d5c5b]">
              Architecture + Interior Design Modern style house design for villas, bungalows &
              farm-houses.
            </p>
            <Link to="/about">
              <button className="border-4 border-black h-14 w-32 justify-center items-center text-center hover:bg-black hover:text-white  text-lg mt-10">
                ABOUT
              </button>
            </Link>
            <Link to="/housing-design">
              <button className="border-4 border-black h-14 w-48 mt-2 hover:bg-black hover:text-white text-lg">
                CLASSICAL HOUSES
              </button>
            </Link>
          </div>
          <LazyLoadImage
            src="https://images.squarespace-cdn.com/content/v1/541eaa20e4b0eb59dead1cd7/6e2014a2-21c0-48ca-bbb8-f05491fc9ea8/Sketch%2BGraphic%2Bmodern.jpg?format=1500w"
            alt=""
            className="lg:w-[70rem] lg:h-[50vh] md:w-[50rem] md:h-[30vh] md:p-4 lg:p-0 w-[100%] "
          />
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default AboutUs
