import Modal from 'Components/Modal/modal'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'

const House = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <div className="bg-white flex justify-center items-center p-4 ">
      <Helmet>
        <title>Featured housing design services in Noida</title>
      </Helmet>
      <div className="flex flex-col justify-center container bg-white gap-20 md:p-0 p-4 text-lg">
        <div className="flex md:flex-row lg:flex-row flex-col lg:justify-center lg:items-center bg-white gap-10 lg:pt-10 pt-2">
          <iframe
            src="https://www.youtube.com/embed/8seX_Ta8-6E?autoplay=1&mute=1"
            alt=""
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[20vh] w-[100%]"
          />
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-3 justify-end lg:pt-0 md:pt-20">
            <p className="text-black font-bold text-2xl md:text-3xl lg:text-4xl">House Design</p>

            <p className="text-[#878787] md:text-xl text-lg">
              The House is a family house for a multi-generational family which aims to embrace the
              cohesiveness of the family members which is held by a common value system just like a
              keystone. Visit the following links for more information about this Project .
            </p>

            <div className="flex">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black">video |</p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center bg-white gap-5">
          <div className="grid md:grid-cols-4 grid-cols-2 container pt-10 gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic1.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic1.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic2.jpg"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic2.jpg')
              }
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic3.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic3.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic4.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic4.jpg')
              }
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-5 container md:pt-20 ">
          <div className="flex flex-col  md:items-center ">
            <div className="flex flex-col lg:w-[20rem] md:w-[15rem] gap-10 ">
              <p className="md:text-4xl text-2xl font-bold text-black">
                Classical
                <br />
                House Design
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-3 text-[#878787] ">
            <div className="flex flex-col text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%]">
              <p>
                Classic house design & classic interiors for farmhouses & villas. Design with a
                touch of enduring charm and gracefulness !
              </p>
            </div>
            <p className="lg:w-[95%] md:w-[90%] md:p-2 md:text-xl text-lg ">
              At the heart of our classical house designs lies a philosophy that values continuity
              and sophistication. We invite you to explore our portfoio and embark on a journey
              where architectural legacy meets the demands of the present, creating homes that stand
              as epitomes of classical elegance in the modern age.
            </p>
          </div>
        </div>

        <div className="flex md:flex-row flex-col lg:justify-center lg:items-center bg-white gap-10  md:pt-10">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic5.jpg"
            alt=""
            className="lg:h-[45vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[20vh] w-[100%]"
          />
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-3 justify-end lg:pt-0 md:pt-14 pt-5">
            <div className="text-black flex-col font-bold md:text-4xl text-2xl">
              <p>Classical</p>
              <p>Classic House</p>
            </div>

            <p className="text-[#878787] lg:w-[25rem] md:w-[15rem] md:text-xl text-lg">
              The design of the house creates harmonious blend of timeless elegance and distinctive
              charm. From the intricate moldings and graceful arches to the expansive landscape,
              Each element is carefully curated to exude an aura of incomparable beauty and
              sophistication.{' '}
            </p>
            <p className="text-[#878787] lg:w-[25rem] md:w-[15rem] md:text-xl text-lg">
              Visit the following links for more information about this Project .
            </p>

            <div className="flex gap-5">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black">video | </p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center bg-white ">
          <div className="grid md:grid-cols-4  grid-cols-2 container  gap-4 md:pt-10 pt-8 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic6.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic6.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic7.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic7.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic8.avif"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic8.avif')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic9.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic9.jpg')
              }
            />
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-5 container  lg:pt-10 pt-5 ">
          <div className="flex flex-col  md:items-center ">
            <div className="flex flex-col lg:w-[20rem] md:w-[15rem] gap-10 ">
              <p className="md:text-4xl text-2xl font-bold text-black">
                Classical
                <br />
                Interior Design
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-3 text-[#878787] ">
            <div className="flex flex-col text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%]">
              <p>
                A timeless appeal that transcend fleeting trends Luxury classical interior design
              </p>
            </div>
            <p className="lg:w-[95%] md:w-[95%] md:p-2 md:text-xl text-lg ">
              Our passion lies in curating spaces that encapsulate timeless sophistication,
              meticulously weaving together the richness of historical aesthetics with the comforts
              of contemporary living. Every detail tells a story of refined artistry. Ornate
              moldings, exquisite chandeliers, and intricate carvings create an ambiance of grandeur
              that transcends eras.
            </p>
          </div>
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2  grid-cols-1 gap-10 p-2 md:pt-10  ">
          <div className="flex flex-col gap-3 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic10.jpg"
              className="lg:h-[45vh] md:h-[40vh] h-[40vh]"
            />
            <p className=" font-bold text-xl text-black">Keyston</p>
            <p className="text-[#878787] md:text-xl text-lg">
              The Keystone house is a symphony of plush fabrics, rich woods, and sumptuous textures
              that envelop you in luxury at every turn.
            </p>
            <p className="text-[#878787]">
              {' '}
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong>VIDEO</strong>
              </Link>
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic12.jpg"
              alt=""
              className="lg:h-[45vh] md:h-[40vh] h-[40vh]"
            />
            <p className=" font-bold text-xl text-black ">Arcane</p>
            <p className="text-[#878787] md:text-xl text-lg">
              The Arcane house is designed as a modern composition with a touch of classical
              elements to create an element of surprise.
            </p>
            <p className="text-black pt-4">
              {' '}
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong> VIDEO</strong>
              </Link>{' '}
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic13.jpg"
              alt=""
              className="lg:h-[45vh] md:h-[40vh] h-[40vh]"
            />
            <p className=" font-bold text-xl text-black">Vintage</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Designed to recreate the vintage charm of old school rustic spaces with exposed
              materials & monocromatic Backdrop.
            </p>
            <p className="text-[#878787]">
              {' '}
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong>VIDEO</strong>
              </Link>{' '}
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center bg-white ">
          <div className="grid md:grid-cols-4 grid-cols-2 w-full gap-4 md:pt-14 pt-5 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic12.png"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic12.png')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic13.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic13.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic14.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic14.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic15.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic15.jpeg'
                )
              }
            />
          </div>
        </div>

        <div className="flex md:flex-row flex-col container  lg:gap-24 md:pt-10 ">
          <div className="flex flex-col gap-3 text-black justify-center md:w-[35%]  w-[100%]">
            <p className=" md:text-4xl text-2xl font-bold">Ashirwadum Architects & developers</p>
            <p className=" md:text-4xl text-2xl font-bold">Modern Houses</p>
            <p className="text-xl text-[#5d5c5b]">
              Architecture + Interior Design Modern style house design for villas, bungalows &
              farm-houses.
            </p>
            <Link to="/about">
              <button className="border-4 border-black h-14 w-32 justify-center items-center text-center hover:bg-black hover:text-white  text-lg mt-10">
                ABOUT
              </button>
            </Link>
            <Link to="/housing-design">
              <button className="border-4 border-black h-14 w-48 mt-2 hover:bg-black hover:text-white text-lg">
                CLASSICAL HOUSES
              </button>
            </Link>
          </div>
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/housing-design/pic16.jpg"
            alt=""
            className="lg:w-[70rem] lg:h-[50vh] md:w-[50rem] md:h-[30vh]  md:p-4 lg:p-0 w-[100%]"
          />
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default House
