import { Divider } from '@mui/material'
import Modal from 'Components/Modal/modal'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

const Office = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const navigate = useNavigate()
  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    appendArrows: '.custom-arrows',
    customPaging: (i) => <button className="slick-dot bg-black rounded-full w-4 h-4"></button>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          arrows: false,
        },
      },
    ],
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const handleConsultUsClick = () => {
    navigate('/office')
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          arrows: false,
        },
      },
    ],
  }
  const slider = [
    {
      id: 1,
      image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/office/slider1.png',
      Title: 'Inverted Office, Interior Design',
    },
    {
      id: 2,
      image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/office/slider2.jpg',
      Title: 'Colorjet Office, Interior Desig',
    },
    {
      id: 3,
      image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/office/slider3.jpg',
      Title: 'Origami Office, Interior Design',
    },
    {
      id: 4,
      image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/office/slider4.webp',
      Title: 'Colorjet Office, Interior Desig',
    },
    {
      id: 5,
      image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/office/slider5.avif',
      Title: 'QA Infotech Lobby, Interior Design',
    },
    {
      id: 6,
      image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/office/slider6.jpg',
      Title: 'Inverted Office, Interior Design',
    },
    {
      id: 7,
      image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/office/slider7.webp',
      Title: 'Origami Office, Interior Design',
    },
    {
      id: 8,
      image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/office/slider8.webp',
      Title: 'QA Infotech Lobby, Interior Design',
    },
    {
      id: 9,
      image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/office/slider10.jpg',
      Title: 'Colorjet Office, Interior Desig',
    },
    {
      id: 10,
      image: 'https://ashirwadum.s3.ap-south-1.amazonaws.com/office/slider9.avif',
      Title: 'Inverted Office, Interior Design',
    },
  ]
  return (
    <div className="bg-white flex justify-center items-center ">
      <Helmet>
        <title>Ashirwadum Office Interior</title>
      </Helmet>
      <div className="flex flex-col  lg:justify-center container bg-white p-4 text-lg">
        <div className="flex md:flex-row lg:flex-row flex-col lg:justify-center lg:items-center bg-white gap-14 lg:pt-10 pt-2">
          <div className="lg:w-[70%] w-[100%]">
            <Slider {...settings2}>
              {slider.map((item) => (
                <div key={item.id} className="p-2">
                  <LazyLoadImage
                    src={item.image}
                    alt={item.Title}
                    className="lg:h-[50vh] md:w-[100%] md:h-[35vh]  h-[30vh] w-[100%]   lg:w-[100%] border-4 shadow-xl p-4"
                  />
                  <div className="mt-2 text-center"></div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="flex flex-col lg:w-[35rem] md:w-[30rem] gap-3 justify-end ">
            <div className="text-black font-bold text-2xl md:text-3xl lg:text-4xl">
              <p>OFFICE </p>
              <p> INTERIOR </p>
              <p>DESIGN</p>
            </div>
            <p className=" text-[#5d5c5b] flex-wrap text-xl font-bold">
              Corporate Offices & Coworking Spaces
            </p>

            <p className="text-[#878787] md:w-[80%]">
              Design of your office is the true reflection of your company's identity and brand
              image!
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center md:px-5 pt-10">
          <div className="flex text-[#5d5c5b]">
            <p className="text-lg font-bold ">
              One-Stop Solution :{' '}
              <p className="text-lg font-normal">Turning your Vision into Reality</p>
            </p>
          </div>
          <div className="grid lg:grid-cols-3  md:grid-cols-2 grid-cols-1 gap-6">
            <div className="shadow-xl border-4">
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic1.jpg"
                onClick={() =>
                  openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic1.jpg')
                }
                alt=""
              />
              <p className="text-xl text-[#5d5c5b] font-bold p-2">CONSULT </p>
              <p className="text-[#878787] p-2">
                Meet the designer to discuss your vision and requirements for the upcoming Office
                Design and reach a desired unique solution for your company.
              </p>
            </div>
            <div className="shadow-xl border-4">
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic2.jpg"
                alt=""
                onClick={() =>
                  openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic2.jpg')
                }
              />
              <p className="text-xl text-[#5d5c5b] font-bold p-2">VISUALIZE</p>
              <p className="text-[#878787] p-2">
                Visualize your office design, building elevation and planning for complete clarity.
                Get preliminary cost estimation and begin construction with proper planning.{' '}
              </p>
            </div>
            <div className="shadow-xl border-4">
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic3.jpg"
                alt=""
                onClick={() =>
                  openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic3.jpg')
                }
              />
              <p className="text-xl text-[#5d5c5b] font-bold p-2">COMPLETION</p>
              <p className="text-[#878787] p-2">
                Proper management allows to minimize cost over-runs, project delays and reach swift
                completion. Get the desired results within projected timelines and costs.{' '}
              </p>
            </div>
          </div>
        </div>
        <Divider className="bg-gray-400 md:!mt-10"></Divider>
        <div className="flex flex-col justify-center md:px-5 pt-10 gap-3">
          <div className="flex text-[#5d5c5b] gap-2">
            <p className="text-xl font-bold ">
              Office Design Projects :
              <p className="text-xl font-normal">8+ years of Domain Expertise </p>{' '}
            </p>
          </div>
          <p className="text-[#878787]">
            We are a leading office design firm in Noida with 8+ years of Specialized Experience.
            Architects and Interior Designers working at the firm have a strong domain expertise in
            designing Corporate offices, Coworking spaces and their interior design to reach desired
            results.
          </p>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 pt-10">
            <div className="text-center ">
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic4.jpg"
                onClick={() =>
                  openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic4.jpg')
                }
                alt=""
                className="lg:h-[45vh] md:h-[40vh] h-[40vh] w-[100%] p-2 border-4"
              />
              <p className="text-xl text-black pt-4">Coworking Spaces</p>
              <strong className="text-[#5d5c5b]">Unboxed</strong>
            </div>
            <div className="text-center">
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic5.jpg"
                alt=""
                className="lg:h-[45vh] md:h-[40vh] h-[40vh] w-[100%] p-2 border-4"
                onClick={() =>
                  openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic5.jpg')
                }
              />
              <p className="text-xl text-black pt-4">Corporate Offices</p>
              <strong className="text-[#5d5c5b]">PG Electroplast </strong>
            </div>
            <div className="text-center">
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic6.jpg"
                alt=""
                className="lg:h-[45vh] md:h-[40vh] h-[40vh] w-[100%] p-2 border-4"
                onClick={() =>
                  openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic6.jpg')
                }
              />

              <p className="text-xl text-black pt-4">Experience Centres</p>
              <strong className="text-[#5d5c5b]">Ren Technologies </strong>
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col gap-3 pt-10">
          <div>
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic7.jpg"
              alt=""
              className="lg:h-[45vh] h-[25vh] p-2 border-4"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic7.jpg')
              }
            ></LazyLoadImage>
          </div>
          <div className="flex flex-col">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic8.webp"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic8.webp')
              }
              alt=""
              className="p-2 border-4"
            />
            <p className="text-[#5d5c5b] text-xl pt-5">Design Inspiration</p>
            <p className="text-[#878787] pt-3">Corporate Office Interiors </p>
            <p className="text-[#878787]">Coworking Space</p>
            <p className="text-[#878787]">Experience Center </p>
            <Link to="/office">
              <button
                className="border-4 border-black md:text-xl  text-lg text-black hover:bg-black hover:!text-white !mt-5 lg::w-[50%] md:w-[80%] w-[100%] md:p-3 p-3"
                onClick={handleConsultUsClick}
              >
                CONSULT US - OFFICE DESIGN !
              </button>
            </Link>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 pt-10">
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic9.jpg"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic9.jpg')
              }
              alt=""
              className="lg:h-[45vh] md:h-[40vh] h-[40vh] p-2 border-4 shadow-xl"
            />
            <p className="text-xl text-black font-bold text-center">Collaborative Spaces</p>

            <p className="text-[#878787]">
              Lounging areas and similar collaborative zones serve as the informal backbone of any
              office space. These spaces allow possibilities of organic collaboration between
              employees thus creating an energetic and dynamic office environment.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic10.jpg"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic10.jpg')
              }
              alt=""
              className="lg:h-[45vh] md:h-[40vh] h-[40vh] p-2 border-4 shadow-xl"
            />
            <p className="text-xl text-black font-bold text-center">Recreational Areas</p>

            <p className="text-[#878787]">
              Cafeterias, pool tables, gaming consoles have now become the centre piece of many
              offices. These recreational spaces not only boost up the energy levels of the
              employees but also convey the transparent work-philosophy that new-age organizations
              boast.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic11.webp"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/office/pic11.webp')
              }
              alt=""
              className="lg:h-[45vh] md:h-[40vh] h-[40vh] p-2 border-4 shadow-xl"
            />
            <p className="text-xl text-black font-bold text-center">Open Workspaces</p>

            <p className="text-[#878787]">
              Working desks have evolved with the changing times and now serve as open environments
              where individuals can express themselves and share their creativity with coworkers.
              Open workspaces reflect the egalitarian and open working style of modern
              organizations.
            </p>
          </div>
        </div>

        <div className="pt-16 flex md:flex-row flex-col font-bold justify-center  ">
          <div className="text-black md:text-4xl  text-3xl md:w-[30%] w-[80%] flex flex-col ">
            <p>
              Office
              <br />
              Interior Design{' '}
            </p>
          </div>
          <div className="flex flex-col md:w-[50%] w-[100%] md:gap-3 gap-7 lg:pt-0 pt-6 ">
            <p className="text-[#5d5c5b] font-bold text-xl">Architecture + Interior design </p>
            <p className="text-[#878787] font-normal md:text-lg text-base">
              Your office design is the reflection of what your brand stands for and what your
              company is made of! Careful planning and an amazing office interior design are the
              essentials to create a unique brand image. The uniqueness of your office design and
              its interior design directly depicts your ability of being uniquely creative.
            </p>
          </div>
        </div>
        <div className="relative pt-10">
          <Slider {...settings}>
            {slider.map((item) => (
              <div key={item.id} className="p-2">
                <LazyLoadImage
                  src={item.image}
                  alt={item.Title}
                  className="w-full h-[35vh] p-2 border-4"
                />
                <div className="mt-2 text-center">
                  <h3 className="text-lg text-black font-bold">{item.Title}</h3>
                  <p className="text-sm text-gray-600">{item.add}</p>
                </div>
              </div>
            ))}
          </Slider>
          <div className="custom-arrows absolute inset-10 flex justify-between items-center px-4">
            <button
              className="slick-prev text-white h-16 w-16 p-4 rounded-full"
              onClick={() => document.querySelector('.slick-prev').click()}
            >
              ❮
            </button>
            <button
              className="slick-next text-white h-16 w-16 p-4 rounded-full"
              onClick={() => document.querySelector('.slick-next').click()}
            >
              ❯
            </button>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default Office
