import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const InsightsDetails = () => {
  const { slug } = useParams()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)

  const insightsDetailsFn = async () => {
    try {
      const response = await axios.get(
        `https://m1blog.aaragroups.com/blog/blog-api/?slug=${slug}`,
        {
          headers: {
            Authorization: 'Token 108f496c431f17940641ad58f8c72bae292c80be',
          },
        }
      )

      if (response.data && response.data.data) {
        setData(response.data.data)
      } else {
        console.error('Unexpected response structure:', response)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    insightsDetailsFn()

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [slug])

  if (loading) {
    return <div className="flex items-center justify-center h-full">Loading...</div>
  }

  if (!data) {
    return <div className="flex items-center justify-center h-full">No data found</div>
  }
  console.log(data.title)

  return (
    <div className="bg-white">
      <Helmet key={''}>
        <title>{data.title}</title>
      </Helmet>

      <div className="bg-white flex justify-center items-center !pt-10">
        <div className="flex flex-col bg-white p-6 gap-10 lg:w-[67%] w-[100%] text-black">
          {data.images && (
            <LazyLoadImage
              src={data.images}
              alt={data.title}
              className="w-[100%] md:h-[60vh] h-[30vh]"
            />
          )}
          <div className="flex flex-col">
            <h1 className="text-3xl font-bold">{data.title}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: data.Content }}
              className="text-gray-800 mt-4 flex flex-col gap-5 text-xl lg:p-0 p-2  font-normal"
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InsightsDetails
