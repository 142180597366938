import Modal from 'Components/Modal/modal'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'

const Institutionaldesign = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <div className="bg-white flex justify-center items-center ">
      <Helmet>
        <title>institutional desingning services in lucknow</title>
      </Helmet>
      <div className="flex flex-col  lg:justify-center container bg-white p-4">
        <div className="flex md:flex-row lg:flex-row flex-col lg:justify-center lg:items-center bg-white gap-10 lg:pt-10 pt-2">
          <iframe
            src="https://www.youtube.com/embed/8seX_Ta8-6E?autoplay=1&mute=1"
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-[100%]"
            frameborder="0"
          ></iframe>
          <div className="flex flex-col lg:w-[45%] md:w-[20rem] gap-3 justify-end lg:pt-0 md:pt-24">
            <p className="text-black font-bold text-2xl md:text-3xl lg:text-4xl">
              Institutional Design Services
            </p>

            <p className="text-[#878787] text-lg ">
              Ashirwadum architects & developers take immense pride in providing top-notch
              institutional design services to its customers.
            </p>
            <p className="text-[#878787] text-lg">
              Since, our operation we have delivered a number of institutional design projects which
              includes schools, pre-schools, universities and colleges.
            </p>
            <p className="text-[#878787] text-lg ">
              Lastly, we ensure that our customers are delighted with our work.{' '}
            </p>
            <p className="text-[#878787] text-lg ">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex flex-wrap gap-2">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black"> VIDEO |</p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center bg-white gap-5">
          <div className="grid  lg:grid-cols-4 md:grid-cols-4  grid-cols-2 container lg:pt-14 md:pt-10 pt-5 gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic1.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic1.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic2.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic2.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic3.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic3.jpeg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic4.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic4.jpg'
                )
              }
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-2 container  lg:pt-20 md:pt-10 pt-5 ">
          <div className="flex flex-col  md:items-center ">
            <div className="flex flex-col lg:w-[20rem] md:w-[15%]  ">
              <p className="md:text-4xl text-2xl font-bold text-black">
                Modern Institutional
                <br />
                Design
              </p>
            </div>
          </div>
          <div className="flex flex-col text-[#878787]  ">
            <p className="md:text-xl text-lg">
              Our strength lies in exceeding the expectations of our customers through a calculative
              design process. So, whenever you select us for institutional design project, then you
              should be rest assured of the fact that our team of professionals would help you out
              in the entire project cycle i.e. from the beginning to the end. Furthermore, our
              institutional design services is primarily aimed at space utilization, ambiance
              creation that can facilitate the learning and at the same time be comfortable for both
              the students and teachers. The best part is that our service are fully customized as
              per the requirement of our customer and we strictly adhere to their budget and time
              constraint.
            </p>
          </div>
        </div>

        <div className="flex md:flex-row lg:flex-row flex-col lg:justify-center lg:items-center bg-white gap-10 lg:pt-20 pt-10">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic5.jpg"
            alt=""
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-[100%] border-4 shadow-xl p-4"
            onClick={() =>
              openModal(
                'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic5.jpg'
              )
            }
          />
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-2 justify-end lg:pt-0 md:pt-24">
            <div className="text-black flex-col font-bold md:text-4xl text-2xl">
              <p>Morden Institutional Design </p>
            </div>

            <p className="text-[#878787] md:text-xl text-lg">
              each institution has its own unique personality and character, and therefore it
              demands for a design that can combine positive learning ambience alongside
              functionality. Ashirvadum architects & developers can very well help you with
              institutional interior design services. So, don't hold back and perhaps get in touch
              with us today for consultation.
            </p>
            <p className="text-[#878787] md:text-xl text-lg">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black"> VIDEO| </p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center bg-white ">
          <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 container  gap-3 lg:pt-14 md:pt-10 pt-5">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic6.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic6.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic7.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic7.jpeg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic8.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic8.jpeg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic9.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic9.webp'
                )
              }
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-3 container lg:pt-20 md:pt-10 pt-5">
          <div className="flex flex-col  md:items-center ">
            <div className="flex flex-col lg:w-[40%] md:w-[15rem] gap-10 ">
              <p className="md:text-4xl text-2xl font-bold text-black">
                Luxury Institutional Design
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-3 text-[#878787] ">
            <div className="flex flex-col text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%]">
              <p> Ashirwadum Architects & developers are leading architects forcommercial Design</p>
            </div>
            <p className="lg:w-[95%] md:w-[95%] md:p-2 md:text-xl text-lg ">
              luxury institutional design aims to create spaces that are both prestigious and
              functional, blending high-end materials and sophisticated aesthetics with cutting-edge
              technology and personalized design solutions. The result is an environment that not
              only reflects the institution’s status and values but also provides a superior
              experience for its users.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 p-2 lg:pt-20 md:pt-10 pt-5 ">
          <div className="flex flex-col gap-3 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic10.jpg"
              className="lg:h-[45vh] md:h-[40vh] h-[40vh] border-4 shadow-2xl p-1"
            />
            <p className=" font-bold text-xl text-black text-center">ZANITH</p>
            <p className="text-[#878787] md:text-xl text-lg p-2">
              Zenith House’s clean lines, expansive windows, and harmonious use of materials create
              a sense of understated elegance.
            </p>
            <p className="text-[#878787] text-center">
              {' '}
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong>VIDEO</strong>
              </Link>{' '}
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic11.jpeg"
              alt=""
              className="lg:h-[45vh] md:h-[40vh] h-[40vh] border-4 shadow-2xl p-1"
            />
            <p className=" font-bold text-xl text-black text-center">TETRIS</p>
            <p className="text-[#878787] md:text-xl text-lg p-2">
              Tetris-Inspired House is a striking fusion of geometric shapes and vibrant colors.
            </p>
            <p className="text-black pt-4 text-center">
              {' '}
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong>VIDEO</strong>
              </Link>{' '}
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic12.jpg"
              alt=""
              className="lg:h-[45vh] md:h-[40vh] h-[40vh] border-4 shadow-2xl p-1"
            />
            <p className=" font-bold text-xl text-black text-center">OASIS</p>
            <p className="text-[#878787] md:text-xl text-lg p-2">
              Oasis House is a celebration of modern aesthetics, exuding an unmistakable energy that
              encapsulates the spirit of the present age.
            </p>
            <p className="text-[#878787] text-center">
              {' '}
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong>VIDEO</strong>
              </Link>{' '}
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center bg-white pt-14">
          <div className="flex justify-center items-center bg-white ">
            <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 container  gap-3 lg:pt-14 md:pt-10 pt-5 ">
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic13.jpg"
                alt=""
                className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
                onClick={() =>
                  openModal(
                    'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic13.jpg'
                  )
                }
              />
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic14.jpg"
                alt=""
                className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
                onClick={() =>
                  openModal(
                    'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic14.jpg'
                  )
                }
              />
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic15.jpg"
                alt=""
                className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
                onClick={() =>
                  openModal(
                    'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic15.jpg'
                  )
                }
              />
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic16.jpg"
                alt=""
                className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
                onClick={() =>
                  openModal(
                    'https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic16.jpg'
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className="flex md:flex-row flex-col container  lg:gap-24 lg:pt-20 md:pt-10 pt-5">
          <div className="flex flex-col gap-3 text-black justify-center md:w-[35%]  w-[100%]">
            <p className=" md:text-4xl text-2xl font-bold">Ashirwadum Architects & developers</p>
            <p className=" md:text-4xl text-2xl font-bold">Modern Houses</p>
            <p className="text-xl text-[#5d5c5b]">
              Architecture + Interior Design Modern style house design for villas, bungalows &
              farm-houses.
            </p>
            <Link to="/about">
              <button className="border-4 border-black h-14 w-32 justify-center items-center text-center hover:bg-black hover:text-white  text-lg mt-10">
                ABOUT
              </button>
            </Link>
            <Link to="/housing-design">
              <button className="border-4 border-black h-14 w-48 mt-2 hover:bg-black hover:text-white text-lg">
                CLASSICAL HOUSES
              </button>
            </Link>
          </div>
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/institutional-design/pic17.jpg"
            alt=""
            className="lg:w-[70rem] lg:h-[50vh] md:w-[50rem] md:h-[30vh] md:p-4 lg:p-0 w-[100%]"
          />
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default Institutionaldesign
