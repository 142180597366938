import { Api, Facebook, Instagram, LinkedIn, Twitter, WhatsApp, YouTube } from '@mui/icons-material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

/**
 * Footer component.
 * Represents the footer section of the application.
 * Typically contains copyright information.
 * @returns {JSX.Element} Footer component JSX
 */
const Footer = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-white flex flex-col justify-center items-center lg:p-4 ">
      <div className="flex flex-col text-black gap-4 text-xl container lg:pt-20 pt-3 lg:p-0 ">
        <div className="flex  md:gap-3 lg:p-0 p-2 flex-wrap">
          <Link to="/projects">
            <p>Projects - </p>
          </Link>
          <Link to="/housing-design">
            <p className="font-bold text-xl cursor-pointer">Classical Houses | </p>
          </Link>
          <Link to="/residential-design">
            <p className="font-bold text-xl cursor-pointer"> Modern Houses |</p>{' '}
          </Link>
          <Link to="/office">
            <p className="font-bold text-xl cursor-pointer">Offices</p>
          </Link>
        </div>
        <div className=" flex flex-col lg:p-0 p-2">
          <Link to="/">
            <p className="text-[#363636] text-xl font-semibold">
              Ashirwadum Architects & developers
            </p>
          </Link>
          <p className="text-xl text-[#5d5c5b] w-[90%]">
            We are an award winning and a leading architecture and interior design firm in Lucknow.
            We are a team of architects and interior designers Committed to delivering high-quality
            projects and innovative design solutions.
          </p>
          <p className="text-xl text-[#5d5c5b] w-[90%]">
            Architects & Interior designers in Lucknow
          </p>
        </div>
        <div className="flex gap-3 cursor-pointer md:pt-3 lg:p-0 p-2">
          <div className="h-9 w-10v flex justify-center items-center border-4 border-black">
            <Link to="https://www.facebook.com/architects.ashirvadum/">
              <Facebook />
            </Link>
          </div>
          <div className="h-9 w-10 border-4 flex justify-center items-center  border-black">
            <Link to="https://api.whatsapp.com/send/?phone=919559555999&text&type=phone_number&app_absent=0">
              <WhatsApp />
            </Link>
          </div>
          <div className="h-9 w-10 border-4 flex justify-center items-center  border-black">
            <Link to="https://www.instagram.com/ashirwadum_architects/">
              <Instagram />
            </Link>
          </div>
          <div className="h-9 w-10 border-4 flex justify-center items-center  border-black">
            <Link to="https://twitter.com/ashirwadum">
              <Twitter />
            </Link>
          </div>

          <div className="h-9 w-10 border-4 flex justify-center items-center  border-black">
            <Link to="https://in.linkedin.com/company/ashirwadum-architects-developers">
              <LinkedIn />
            </Link>
          </div>
          <Link to="https://www.youtube.com/watch?v=LPTSpd7OGhk">
            <div className="h-9 w-10 border-4 flex justify-center items-center  border-black">
              <YouTube />
            </div>
          </Link>
        </div>
      </div>
      <div className="md:!pt-5  flex flex-col text-black container  w-full  lg:p-0 p-2">
        <p className="text-[#363636] text-xl font-semibold cursor-pointer">Domain expertise : </p>
        <div className="text-xl text-[#5d5c5b]">
          <Link to="/housing-design">
            <p>House Design ( Architecture , Unique Elevation Designs )</p>
          </Link>
          <Link to="/interior-design">
            <p>Home Interior Design ( High-End Houses, Villas and Bungalows ) </p>
          </Link>
          <Link to="/office">
            <p>
              Office Interior Design ( IT office Design , Coworking space design & Coporate Office
              design)
            </p>
          </Link>
        </div>
        <div className="flex md:gap-5 gap-2 cursor-pointer text-black flex-wrap whitespace-normal">
          <p className="font-bold text-lg">Noida |</p>
          <p className="font-bold text-lg"> Lucknow </p>
        </div>
        <div className="text-xl text-[#363636] flex flex-col gap-2 cursor-pointer pt-3">
          <Link to="/best-architects-noida">
            <p className="hover:text-blue-500 hover:underline">Best Architects Noida </p>
          </Link>
          <Link to="/best-architect-firm">
            <p className="hover:text-blue-500 hover:underline">Best Architect Firm in Lucknow</p>
          </Link>
        </div>
        <Link to="/contact">
          <button className="p-3 w-40 mt-6 bg-black hover:bg-[#787878] text-white text-center justify-center font-bold items-center text-xl ">
            Contact
          </button>
        </Link>
        <p className="text-black pt-5 text-xl font-medium">Site Map</p>
        <p className="text-[#5d5c5b] text-xl hover:cursor-pointer ">
          Copyright © 2024
          <Link to="/" className="hover:text-blue-500 hover:underline">
            Ashirwadum Architects & developers
          </Link>
          |
          <a
            href="https://aaragroups.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-500 hover:underline"
          >
            Website Designed by aaragroups
          </a>
        </p>
      </div>
    </div>
  )
}

export default Footer
