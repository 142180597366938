import Modal from 'Components/Modal/modal'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Bestarchitectfirm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <div className="flex flex-col justify-center items-center p-4 bg-white">
      <Helmet>
        <title>Best Architect Firm in Lucknow | Ashirwadum Architects and Developers</title>
      </Helmet>
      <div className="flex flex-col container justify-center items-center">
        <iframe
          src="https://ashirwadum.com/images/resource/drawing.mp4"
          alt=""
          className="lg:h-[50vh] h-[25vh] w-[100%]  md:h-[35vh] lg:w-[90%] md:w-[90%] md:p-0  "
        />
        <div className="flex md:flex-row flex-col gap-5 lg:pt-24  pt-10">
          <div className="flex flex-col  lg:items-center lg:w-[30%] w-[100%] ">
            <div className="flex flex-col  lg:gap-10 gap-5 ">
              <p className="lg:text-4xl text-3xl font-bold  text-center text-black">
                Architect Firm{' '}
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:w-[60%] w-[100%] text-[#5d5c5b] lg:px-0 lg:text-xl md:text-xl text-lg">
            <p className="">
              Ashirwadum architects & developers falls under one of the best architect firms in
              Lucknow. Ashirwadum Group is a leading architectural firm based in Lucknow, NOIDA and
              other part of the country. We offer services ranging from Urban Planning to Landscape
              design, Housing design to Commercial design, Residential Design to Interior design &
              more." Ashirwadum Architects and Developers has rich numbers of highly talented
              professionals.
            </p>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col gap-10 lg:pt-24 pt-10">
          <div className="flex">
            <LazyLoadImage
              src="https://i0.wp.com/www.homebeautiful.com.au/wp-content/uploads/sites/2/umb-media/18163/300718-comfortable-sofa-9.jpg?w=1152&ssl=1"
              alt=""
              className=" lg:h-[60vh] md:h-[35vh] h-[25vh] lg:w-[95%] w-[100%]"
            />
          </div>
          <div className="flex flex-col  lg:w-[50%] w-[100%]  p-2   ">
            <p className="lg:text-4xl text-3xl font-bold p-2 text-black">
              Why Ashirwadum Architects & Developers Claims to be the Best Architect Firm in Lucknow
              ?
            </p>
            <div className="flex flex-col text-[#5d5c5b] lg:text-xl md:text-xl text-lg p-2 gap-3">
              <p>
                Ashirwadum is not only a firm that specializes in building but it also offers a
                range of other services related to architecture. We provide residential consultation
                services along with housing and commercial consultation too, which is really useful
                if you are planning to build a house or building for commercial purposes.
              </p>
              <p>
                Ashirwadum also carries out landscape architecture consultation services. This
                includes the consulting of designs for roads, parks, gardens, monuments, lakes, dams
                and other structures. We offer outstanding building consultation services. These
                include designing, planning and construction of residential, commercial and
                institutional buildings.
              </p>
              <p>
                The main area of expertise of Ashirwadum Architects and Developers is related to
                infrastructure. We have created milestones in many projects completed across
                Lucknow, NOIDA, Delhi and other parts of India. Ashirwadum has designed numerous
                residential buildings and has worked in various areas of infrastructure in Lucknow.
              </p>
              <p>
                We are an architect firm in Lucknow have the potential to transform your home or
                office into a place that has a good ambience, a pleasant and a unique appearance.
                With so many designs, colours and materials available today, the architectural firm
                in Lucknow can help you design a space that meets your needs and requirements.{' '}
              </p>
            </div>
          </div>
        </div>
        <p className="lg:lg:text-4xl  text-3xl font-bold p-2 text-black lg:pt-14 pt-10">
          When looking for a reputable architect firm in Lucknow, the following points need to be
          kept in mind.
        </p>
        <div className="flex lg:flex-row flex-col gap-5  pt-10">
          <div className="flex flex-col  lg:w-[50%] w-[100%] text-[#5d5c5b] lg:px-0 lg:text-xl md:text-xl text-lg  p-2   ">
            <p className=" p-2">
              -The architectural firm should be capable of designing a space that is both functional
              and aesthetically pleasing to the eye. It is important that the architect firm in
              Lucknow is able to meet the client's requirements without sacrificing their quality
              and efficiency.
            </p>
            <p className="p-2 ">
              -Before hiring an architect, it is important to evaluate their designer's reputation
              and experience. While looking for an architect, it is advisable to choose one who has
              been working in the industry for several years and who ca2n show examples of their
              work. The architectural firm in Lucknow should have some examples of the projects they
              have worked on so that you can see how well they do with a specific project.
            </p>
            <p className="p-2 ">
              -It is essential to choose a qualified architect. While hiring an architect, it is
              important to choose one who will provide you the best of service without having to
              compromise with required quality. You should also keep in mind overall package for the
              project.
            </p>
            <p className="p-2">
              -Look at the overall aspect of the firm as it relates to your expectations. After all,
              you would like a company that will meet all of your requirements, thus, you want a
              firm that you can trust.
            </p>
            <p className="p-2">
              Hiring an architect firm in Lucknow is a great way to ensure that you have a
              well-designed and well managed office building. Internet and various referrals can
              help you save a lot of time and money.{' '}
            </p>
            <p className="p-2">
              You will also have to take time to research on the background of the good
              architectural firm in Lucknow, in order to ensure that they have worked well with
              others in the past. You can get information on the website of the company, in addition
              to on the company website of the architecture firm itself.
            </p>
          </div>
          <div className="flex    ">
            <LazyLoadImage
              src="https://media.blogto.com/uploads/2022/04/12/1649792756-20220412-3201-highway-7-1.jpg?w=1400&cmd=resize&height=2500&quality=70"
              alt=""
              className=" lg:h-[60vh] md:h-[35vh] h-[25vh] lg:w-[100%] w-[100%]"
            />
          </div>
        </div>
        <div className="flex flex-col p-2  text-[#5d5c5b] lg:px-0 lg:text-xl md:text-xl text-lg pt-10">
          <p>
            While you are browsing the website, it is important to be careful about what you are
            actually looking for when it comes to the architecture firm. The best architectural firm
            in Lucknow should be able to provide a clear explanation of all of their projects. You
            should also check the architect firm's portfolio of work, in order to get a better idea
            about the kind of work that they provide to clients.
          </p>
          <p>
            It is vital that you ensure that the architectural firm in Lucknow that you choose has
            the right experience and the right resources. You should hire the firm that has a long
            track record of successful projects in Lucknow.
          </p>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default Bestarchitectfirm
