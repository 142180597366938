import Router from 'Router'
import 'index.css'
import ReactDOM from 'react-dom/client'

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-PF58G2FW",
};
TagManager.initialize(tagManagerArgs);
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<Router />)
