import React, { useState } from 'react';

const Modal = ({ isOpen, onClose, imageSrc }) => {
  const [zoom, setZoom] = useState(1);  

  if (!isOpen) return null;

  const zoomIn = () => setZoom((prevZoom) => prevZoom * 1.2);
  const zoomOut = () => setZoom((prevZoom) => Math.max(prevZoom / 1.2, 1));  
 
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
      onClick={handleOverlayClick} 
    >
      <div
        className="relative bg-white p-6 rounded-lg"
        onClick={(e) => e.stopPropagation()} 
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-black text-xl font-bold"
        >
          &times;
        </button>
        <div className="relative">
          <img
            src={imageSrc}
            alt="Modal Content"
            className="transition-transform duration-500 lg:w-[30rem] lg:h-96 h-80 w-[20rem]"
            style={{ transform: `scale(${zoom})` }}
          />
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
            <button
              onClick={zoomIn}
              className="bg-gray-800 text-white px-4 py-2 rounded"
            >
              Zoom In
            </button>
            <button
              onClick={zoomOut}
              className="bg-gray-800 text-white px-4 py-2 rounded"
            >
              Zoom Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
