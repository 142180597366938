import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate } from 'react-router-dom'

const Insights = () => {
  const [data, setData] = useState([])

  const navigate = useNavigate()

  const insightsFn = async () => {
    const response = await axios('https://m1blog.aaragroups.com/blog/blog-api/', {
      headers: {
        Authorization: 'Token 108f496c431f17940641ad58f8c72bae292c80be',
      },
    })
    setData(response.data.data)
    console.log(response.data)
  }

  useEffect(() => {
    insightsFn()
  }, [])

  const handleImageClick = (slug) => {
    navigate(`/blogs/${slug}`)
  }

  const removeHtmlTags = (text) => {
    return text.replace(/<\/?[^>]+>/gi, '')
  }

  const truncateContent = (content, wordLimit) => {
    const plainText = removeHtmlTags(content)
    const words = plainText.split(/\s+/)
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...'
    }
    return plainText
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <div className="bg-white flex justify-center items-center lg:p-4 ">
      <Helmet>
        <title>Ashirwadum Blog in lucknow</title>
      </Helmet>
      <div className="flex flex-col container bg-white lg:p-0 p-4 text-lg ">
        <div className="flex md:flex-row  flex-col w-full md:gap-20 gap-10">
          <div className="flex flex-col md:w-[70%] w-[100%] ">
            {data.map((detail) => (
              <div
                className="text-black flex flex-col gap-3"
                key={detail.id}
                onClick={() => handleImageClick(detail.slug)}
              >
                <LazyLoadImage
                  src={detail.images}
                  alt=""
                  className=" pt-10 lg:h-[70vh]  md:h-[50vh] h-[33vh] w-[100%]"
                />
                <div className="w-[90%] text-2xl font-bold">{detail.title}</div>
                <p className="text-gray-600 w-[100%] text-lg p-2">
                  {truncateContent(detail.Content, 54)}
                </p>
                <p className=" bg-slate-100 p-2 w-36 text-lg cursor-pointer">Read More</p>
              </div>
            ))}
          </div>
          <div className="flex flex-col text-black pt-10 md:w-[20%] w-[100%] ">
            <p className="text-4xl font-bold">
              Architects &
              <br /> Interior Designers
            </p>
            <p className="lg:text-xl text-lg">
              Ashirwadum Architects & developershas established itself, as one of the best
              architects & interior designers in Noida . With more than decade of experience, in
              residential & Office interior domains, Ashirwadum Architects & developershas designed
              a plethora of renowned House Design and Office Interior Design in Delhi , Noida & NCR.
            </p>
            <div className="flex flex-col  lg:pt-20 md:pt-10 pt-5">
              <p className="font-bold text-2xl text-[#787878]">BLOG -</p>
              <p className="text-[#5d5c5b] text-xl">Popular Posts</p>
            </div>

            {data.map((title) => (
              <div
                className="flex flex-col pt-10 md:w-[50%] w-[100%] "
                key={''}
                onClick={() => handleImageClick(title.slug)}
              >
                <p className="font-bold cursor-pointer">{title.title}</p>
                <p className="text-[#5d5c5b] ">Nov 20, 2023</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Insights
