import { Divider } from '@mui/material'
import Modal from 'Components/Modal/modal'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'

const UrbanPlanning = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <div className="bg-white flex justify-center items-center ">
      <Helmet>
        <title>Urban Planning services in Noida</title>
      </Helmet>
      <div className="flex flex-col  lg:justify-center container bg-white p-4 text-lg">
        <div className="flex md:flex-row lg:flex-row flex-col lg:justify-center lg:items-center bg-white gap-10 lg:pt-10 pt-2">
          <iframe
            src="https://www.youtube.com/embed/8seX_Ta8-6E?autoplay=1&mute=1"
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-[100%]"
            frameborder="0"
          ></iframe>
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-2 justify-end lg:pt-0 md:pt-24">
            <p className="text-black font-bold text-2xl md:text-3xl lg:text-4xl">Urban planning</p>

            <p className="text-[#878787] text-lg ">
              Ashirwadum design & architects have significant hands-on experience in delivering
              urban planning projects as we have been serving this industry for a very long time. It
              includes Zonal planning, regional planning, Infrastructure planning, Master planning
              and special area development planning.
            </p>
            <p className="text-[#878787] text-lg ">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex flex-wrap gap-2 cursor-pointer">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black">VIDEO |</p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center bg-white gap-5">
          <div className="grid lg:grid-cols-4 md:grid-cols-4  grid-cols-2  lg:pt-14 md:pt-10 pt-5 gap-5">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic1.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic1.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic2.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic2.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic3.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic3.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic4.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic4.webp')
              }
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-2 container pt-20 ">
          <div className="flex flex-col  md:items-center ">
            <div className="flex flex-col lg:w-[20rem] md:w-[15%]  gap-10 ">
              <p className="md:text-4xl text-2xl font-bold text-black">
                Modern
                <br />
                Urban Planning
              </p>
            </div>
          </div>
          <div className="flex flex-col text-[#878787]  ">
            <p className="text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%] w-[75%]">
              Modern luxury house design for farmhouses & villas Architecture + Interior Design
            </p>

            <p className="lg:w-[95%] md:w-[95%] md:text-xl text-lg">
              Step into a realm where modern design seamlessly blurs the boundaries between indoors
              and nature, ushering in a truly holistic living experience.
            </p>
            <p className="md:text-xl text-lg">
              Our potential in urban planning is backed with original research in Innovation, Domain
              knowledge and sustainability all of which helps us to fulfil our goal to provide
              future cities that are economically feasible, socially acceptable and sustainable
              environmentally. Our team at Ashirwadum design & architects is equipped with
              professional planners who have extensive experience in different sectors such as
              social planning, Financial planning, Urban policy, Recreational planning, to name a
              few. Our work scope includes preparation of Base maps, City mapping, Infrastructure
              analysis, Socio- economic surveys, policy-level recommendations and discussion with
              various government agencies.
            </p>
          </div>
        </div>

        <div className="flex md:flex-row lg:flex-row flex-col lg:justify-center lg:items-center bg-white gap-10 pt-20 ">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic5.jpg"
            alt=""
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-[100%]"
          />
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-3 justify-end lg:pt-0 md:pt-24">
            <div className="text-black flex-col font-bold md:text-4xl text-2xl">
              <p> Modern Urban Planning </p>
            </div>

            <p className="text-[#878787] md:text-xl text-lg">
              Modern houses often showcase minimalist design, characterized by clean lines and
              simple forms. This aesthetic avoids unnecessary ornamentation, focusing instead on
              creating spaces that feel open and uncluttered. The design typically features open
              floor plans that unify living, dining, and kitchen areas, fostering a sense of
              spaciousness and fluid movement throughout the home.
            </p>
            <p className="text-[#878787] md:text-xl text-lg">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black"> VIDEO |</p>
              </Link>
              <Link to="/blog">
                <p className="text-black"> BLOGS</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center bg-white ">
          <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 container  gap-3 pt-14 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic6.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic6.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic7.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic7.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic8.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic8.jpg')
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic9.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic9.jpeg')
              }
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-5 container  lg:pt-20 md:pt-10 pt-5 ">
          <div className="flex flex-col  md:items-center ">
            <div className="flex flex-col lg:w-[20rem] md:w-[15rem] gap-10 ">
              <p className="md:text-4xl text-2xl font-bold text-black">Luxury Urban Planning</p>
            </div>
          </div>
          <div className="flex flex-col gap-3 text-[#878787] ">
            <div className="flex flex-col text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%]">
              <p>Contemporary & modern approach to interior design</p>
              <p> Ashirwadum Architects & developersare leading architects for home design</p>
            </div>
            <p className="lg:w-[95%] md:w-[95%] md:p-2 md:text-xl text-lg ">
              the firm's luxury urban planning approach is about creating environments that are not
              just visually stunning but also offer unparalleled comfort, convenience, and
              sophistication. Their work reflects a commitment to blending high-end design with
              innovative, sustainable practices, ensuring that each project stands as a testament to
              modern luxury living.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 p-2  lg:pt-20 md:pt-10 pt-5">
          <div className="flex flex-col gap-3 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic10.png"
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
            />
            <p className=" font-bold text-xl text-black">ZANITH</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Zenith House’s clean lines, expansive windows, and harmonious use of materials create
              a sense of understated elegance.
            </p>
            <p className="text-[#878787]">
              {' '}
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong> VIDEO</strong>
              </Link>{' '}
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>{' '}
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic11.webp"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
            />
            <p className=" font-bold text-xl text-black ">TETRIS</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Tetris-Inspired House is a striking fusion of geometric shapes and vibrant colors.
            </p>
            <p className="text-black pt-4">
              {' '}
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong>VIDEO</strong>
              </Link>{' '}
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic12.jpeg"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
            />
            <p className=" font-bold text-xl text-black">OASIS</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Oasis House is a celebration of modern aesthetics, exuding an unmistakable energy that
              encapsulates the spirit of the present age.
            </p>
            <p className="text-[#878787]">
              {' '}
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong>VIDEO</strong>
              </Link>{' '}
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center bg-white ">
          <div className="grid md:grid-cols-4  grid-cols-2 container  gap-3  lg:pt-14 md:pt-10 pt-5">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic13.avif"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic13.avif'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic14.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic14.webp'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic15.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic15.jpeg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic16.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal('https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic16.jpg')
              }
            />
          </div>
        </div>

        <div className="flex md:flex-row flex-col container  lg:gap-24 lg:pt-20 md:pt-10 pt-5">
          <div className="flex flex-col gap-3 text-black justify-center md:w-[35%]  w-[100%]">
            <p className=" md:text-4xl text-2xl font-bold">Ashirwadum Architects & developers</p>
            <p className=" md:text-4xl text-2xl font-bold">Modern Houses</p>
            <p className="text-xl text-[#5d5c5b]">
              Architecture + Interior Design Modern style house design for villas, bungalows &
              farm-houses.
            </p>
            <Link to="/about">
              <button className="border-4 border-black h-14 w-32 justify-center items-center text-center hover:bg-black hover:text-white  text-lg mt-5">
                ABOUT
              </button>
            </Link>
            <Link to="/housing-design">
              <button className="border-4 border-black h-14 w-48 mt-2 hover:bg-black hover:text-white text-lg">
                CLASSICAL HOUSES
              </button>
            </Link>
          </div>
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/urban-planning/pic17.jpg"
            alt=""
            className="lg:w-[70rem] lg:h-[50vh] md:w-[50rem] md:h-[30vh] md:p-4 lg:p-0 w-[100%]"
          />
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default UrbanPlanning
