import { Divider } from '@mui/material'
import Modal from 'Components/Modal/modal'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'

const Residentialdesign = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <div className="bg-white flex justify-center items-center ">
      <Helmet>
        <title>top residential designing work</title>
      </Helmet>

      <div className="flex flex-col  lg:justify-center container bg-white p-4">
        <div className="flex md:flex-row lg:flex-row flex-col lg:justify-center lg:items-center bg-white gap-10  text-lg lg:pt-10 pt-2">
          <iframe
            src="https://www.youtube.com/embed/8seX_Ta8-6E?autoplay=1&mute=1"
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-[100%]"
            frameborder="0"
          ></iframe>
          <div className="flex flex-col lg:w-[45%] md:w-[20rem] gap-3 justify-end lg:pt-0 md:pt-24">
            <p className="text-black font-bold text-2xl md:text-3xl lg:text-4xl">
              Residential Design Services
            </p>

            <p className="text-[#878787] text-lg ">
              We at Ashirwadum architects & developers offer human-centered approach towards
              workspace design and Residential interiors.
            </p>
            <p className="text-[#878787] text-lg">
              It is backed with innovative finishes, space planning, textures, colors, surfaces and
              furniture. Furthermore, our experienced team approaches the project keeping a number
              of aspects in mind such as deadlines, budgets, market-driven research etc.{' '}
            </p>
            <p className="text-[#878787] text-lg ">
              Lastly, we ensure that our customers are delighted with our work.{' '}
            </p>
            <p className="text-[#878787] text-lg ">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex flex-wrap gap-2">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black"> VIDEO |</p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center bg-white gap-5">
          <div className="grid  lg:grid-cols-4 md:grid-cols-4  grid-cols-2 container pt-14 gap-4">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic1.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic1.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic2.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic2.webp'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic3.png"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic3.png'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic4.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic4.jpg'
                )
              }
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-5 container  pt-20 ">
          <div className="flex flex-col  md:items-center ">
            <div className="flex flex-col lg:w-[20rem] md:w-[15%]  gap-10 ">
              <p className="md:text-4xl text-2xl font-bold text-black">
                Modern
                <br />
                Residential Design
              </p>
            </div>
          </div>
          <div className="flex flex-col text-[#878787]  ">
            <p className="text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%] w-[75%]">
              Modern commerical design for farmhouses & villas Architecture + Interior Design
            </p>

            <p className="md:text-xl text-lg">
              modern Residential design is about creating environments that are not only visually
              appealing but also practical, adaptable, and aligned with contemporary values and
              technological advancements.
            </p>
          </div>
        </div>
        <Divider className="!mt-14 bg-gray-600 container "></Divider>
        <div className="flex md:flex-row lg:flex-row flex-col lg:justify-center lg:items-center bg-white gap-10 lg:pt-12 pt-2">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic5.jpg"
            onClick={() =>
              openModal(
                'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic5.jpg'
              )
            }
            alt=""
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-[100%]"
          />
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-3 justify-end lg:pt-0 md:pt-24">
            <div className="text-black flex-col font-bold md:text-4xl text-2xl">
              <p>Morden Residential Design </p>
            </div>

            <p className="text-[#878787] md:text-xl text-lg">
              Modern Residential design is all about creating functional, stylish, and efficient
              spaces tailored to business needs. It blends aesthetics with practicality, often
              featuring minimalist aesthetics, open layouts, and a focus on sustainability. Large
              windows and flexible, modular furniture are common, allowing for adaptable spaces that
              can evolve with changing needs. Technology integration is key, from smart lighting to
              interactive displays, enhancing both customer experience and operational efficiency.
            </p>
            <p className="text-[#878787] md:text-xl text-lg">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black"> VIDEO |</p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center bg-white ">
          <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 container  gap-4  lg:pt-14 md:pt-10 pt-5 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic6.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic6.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic7.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic7.jpeg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic8.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic8.jpeg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic9.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic9.webp'
                )
              }
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-5 container  lg:pt-20 md:pt-10 pt-5">
          <div className="flex flex-col  md:items-center ">
            <div className="flex flex-col lg:w-[40%] md:w-[15rem] gap-10 ">
              <p className="md:text-4xl text-2xl font-bold text-black">
                Luxury Residential Design{' '}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-3 text-[#878787] ">
            <div className="flex flex-col text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%]">
              <p>
                {' '}
                Ashirwadum Architects & developers are leading architects for Residential Design
              </p>
            </div>
            <p className="lg:w-[95%] md:w-[95%] md:p-2 md:text-xl text-lg ">
              luxury Residential design is about creating spaces that offer unparalleled elegance
              and comfort while providing functional, high-end solutions that enhance the business's
              image and operations. It’s a balance of aesthetic splendor, superior materials, and
              innovative technology, aimed at delivering an extraordinary experience for clients,
              customers, and employees.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 p-2  lg:pt-20 md:pt-10 pt-5 ">
          <div className="flex flex-col gap-3 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic10.jpg"
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic10.jpg'
                )
              }
            />
            <p className=" font-bold text-xl text-black">ZANITH</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Zenith House’s clean lines, expansive windows, and harmonious use of materials create
              a sense of understated elegance.
            </p>
            <p className="text-[#878787]">
              {' '}
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong>VIDEO</strong>
              </Link>{' '}
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic11.jpg"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic11.jpg'
                )
              }
            />
            <p className=" font-bold text-xl text-black ">TETRIS</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Tetris-Inspired House is a striking fusion of geometric shapes and vibrant colors.
            </p>
            <p className="text-black pt-4">
              {' '}
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong>VIDEO</strong>
              </Link>{' '}
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic12.jpg"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic12.jpg'
                )
              }
            />
            <p className=" font-bold text-xl text-black">OASIS</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Oasis House is a celebration of modern aesthetics, exuding an unmistakable energy that
              encapsulates the spirit of the present age.
            </p>
            <p className="text-[#878787]">
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong>VIDEO</strong>
              </Link>{' '}
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
        </div>

        <div className="mt-20">
          <Divider className="bg-gray-600 container "></Divider>
        </div>

        <div className="flex justify-center items-center bg-white ">
          <div className="flex justify-center items-center bg-white ">
            <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 container  gap-4  lg:pt-14 md:pt-10 pt-5 ">
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic13.jpg"
                alt=""
                className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
                onClick={() =>
                  openModal(
                    'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic13.jpg'
                  )
                }
              />
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic14.jpg"
                alt=""
                className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
                onClick={() =>
                  openModal(
                    'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic14.jpg'
                  )
                }
              />
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic15.jpg"
                alt=""
                className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
                onClick={() =>
                  openModal(
                    'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic15.jpg'
                  )
                }
              />
              <LazyLoadImage
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic16.avif"
                alt=""
                className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
                onClick={() =>
                  openModal(
                    'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic16.avif'
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className="flex md:flex-row flex-col container  lg:gap-24  lg:pt-20 md:pt-10 pt-5">
          <div className="flex flex-col gap-3 text-black justify-center md:w-[35%]  w-[100%]">
            <p className=" md:text-4xl text-2xl font-bold">Ashirwadum Architects & developers</p>
            <p className=" md:text-4xl text-2xl font-bold">Modern Houses</p>
            <p className="text-xl text-[#5d5c5b]">
              Architecture + Interior Design Modern style house design for villas, bungalows &
              farm-houses.
            </p>
            <Link to="/about">
              <button className="border-4 border-black h-14 w-32 justify-center items-center text-center hover:bg-black hover:text-white  text-lg mt-10">
                ABOUT
              </button>
            </Link>
            <Link to="/housing-design">
              <button className="border-4 border-black h-14 w-48 mt-2 hover:bg-black hover:text-white text-lg">
                CLASSICAL HOUSES
              </button>
            </Link>
          </div>
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic17.jpg"
            alt=""
            className="lg:w-[70rem] lg:h-[50vh] md:w-[50rem] md:h-[30vh] md:p-4 lg:p-0 w-[100%]"
            onClick={() =>
              openModal(
                'https://ashirwadum.s3.ap-south-1.amazonaws.com/residential-design/pic17.jpg'
              )
            }
          />
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default Residentialdesign
