import { Paper, ThemeProvider, createTheme } from '@mui/material'
import { Footer, Header } from 'Components/Common'

import { Outlet } from 'react-router-dom'
import usePageView from '../usePageView'

const Layout = () => {
  usePageView()
  return (
    <Paper>
      <Header />

      <Outlet />

      <Footer />
    </Paper>
  )
}

export default Layout
