import React, { useState } from 'react'
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { Link } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Menu } from '@mui/icons-material'

const Header = ({ setMode, mode }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [expandedAccordion, setExpandedAccordion] = useState(false)
  const [hoveredLink, setHoveredLink] = useState(null)
  const [dropdownHover, setDropdownHover] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState()

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open)
  }

  const handleAccordionChange = () => {
    setExpandedAccordion(!expandedAccordion)
  }

  const menuItems = [
    { text: 'Home', path: '/' },
    { text: 'About', path: '/about' },
    {
      text: 'Services',
      path: '#',
      isDropdown: true,
      dropdownItems: [
        { text: 'Urban Planning', path: 'urban-planning' },
        { text: 'Landscape Design', path: 'landscape-design' },
        { text: 'House Design', path: '/housing-design' },
        { text: 'Commercial Design', path: 'commercial-design' },
        { text: 'Residential Design', path: 'residential-design' },
        { text: 'Institution Design', path: 'institutional-design' },
        { text: 'Interior Design', path: 'interior-design' },
      ],
    },
    { text: 'Project', path: '/projects' },
    { text: 'Office', path: '/office' },
    { text: 'Blog', path: '/blog' },
    { text: 'Contact', path: '/contact' },
  ]

  const linkStyle = {
    textDecoration: 'none',
    color: 'black',
    position: 'relative',
    padding: '1px',
  }

  const underlineStyle = {
    position: 'absolute',
    left: 0,
    bottom: 0,
    height: '4px',
    backgroundColor: 'orange',
    width: '0%',
    transition: 'width 0.10s ease',
  }

  return (
    <div className=" sticky top-0 left-0 w-full z-10 bg-white">
      <div className="flex  flex-col lg:flex-row justify-center items-center bg-white">
        <div className="flex flex-col-reverse lg:flex-row lg:justify-between container lg:pt-5 p-2 text-xl">
          <div className="flex flex-col items-center lg:items-start text-center lg:text-left">
            <Link to="/" className=" p-2">
              <img
                src="https://ashirwadum.s3.ap-south-1.amazonaws.com/ashirwadumlogo.png"
                alt="Logo"
                className="h-24 w-auto"
              />
            </Link>
          </div>
          <div className="flex lg:flex-row !bg-white items-center lg:items-center lg:justify-between px-3 text-black ">
            <div className="lg:hidden text-black">
              <IconButton onClick={toggleDrawer(true)} className="" sx={{ color: 'black' }}>
                <Menu fontSize="large" />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                PaperProps={{ style: { width: '50%' } }}
              >
                <div className="flex flex-col  h-screen  text-xl w-[100%] bg-white p-2">
                  <List className=" bg-white  ">
                    {menuItems.map((item) => (
                      <ListItem
                        key={item.text}
                        className="w-full gap-5 bg-white"
                        onClick={item.isDropdown ? handleAccordionChange : toggleDrawer(false)}
                      >
                        {item.isDropdown ? (
                          <Accordion
                            expanded={expandedAccordion}
                            onChange={handleAccordionChange}
                            sx={{
                              width: '100%',
                              background: 'white',
                              color: 'black',
                              padding: '0',
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon className="text-black" />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className=""
                            >
                              <span>{item.text}</span>
                            </AccordionSummary>
                            <AccordionDetails className="">
                              <List>
                                {item.dropdownItems.map((dropdownItem) => (
                                  <ListItem key={dropdownItem.text} style={{ padding: ' 0px 0' }}>
                                    <Link to={dropdownItem.path} style={{ ...linkStyle }}>
                                      {dropdownItem.text}
                                    </Link>
                                  </ListItem>
                                ))}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        ) : (
                          <Link
                            to={item.path}
                            style={{ ...linkStyle, position: 'relative', display: 'block' }}
                            onMouseEnter={() => setHoveredLink(item.text)}
                            onMouseLeave={() => setHoveredLink(null)}
                          >
                            {item.text}
                            <div
                              style={{
                                ...underlineStyle,
                                width: hoveredLink === item.text ? '100%' : '0%',
                              }}
                            />
                          </Link>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Drawer>
            </div>
            <div className="hidden lg:flex flex-wrap items-center  gap-10 bg-white whitespace-nowrap">
              {menuItems.map((item) =>
                item.isDropdown ? (
                  <div
                    key={item.text}
                    style={{ ...linkStyle, position: 'relative' }}
                    onMouseEnter={() => {
                      setDropdownOpen(true)
                      setDropdownHover(true)
                    }}
                    onMouseLeave={() => {
                      if (!dropdownHover) {
                        setDropdownOpen(false)
                      }
                    }}
                  >
                    {item.text}
                    <div
                      className={`absolute left-0 w-[14rem] bg-white shadow-lg border border-gray-300 z-50 ${
                        dropdownOpen ? 'block' : 'hidden'
                      }`}
                      style={{ top: '100%', left: 0, right: 0 }}
                      onMouseEnter={() => setDropdownHover(true)}
                      onMouseLeave={() => {
                        setDropdownHover(false)
                        setDropdownOpen(false)
                      }}
                    >
                      <List>
                        {item.dropdownItems.map((dropdownItem) => (
                          <ListItem key={dropdownItem.text} className="w-full">
                            <Link to={dropdownItem.path} style={{ ...linkStyle }}>
                              {dropdownItem.text}
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </div>
                    <div
                      style={{
                        ...underlineStyle,
                        width: hoveredLink === item.text ? '100%' : '0%',
                      }}
                    />
                  </div>
                ) : (
                  <Link
                    to={item.path}
                    key={item.text}
                    style={{ ...linkStyle }}
                    onMouseEnter={() => setHoveredLink(item.text)}
                    onMouseLeave={() => setHoveredLink(null)}
                  >
                    {item.text}
                    <div
                      style={{
                        ...underlineStyle,
                        width: hoveredLink === item.text ? '100%' : '0%',
                      }}
                    />
                  </Link>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
