import { Divider } from '@mui/material'
import Modal from 'Components/Modal/modal'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'

const Landscapedesign = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState('')
  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentImage('')
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <div className="bg-white flex justify-center items-center ">
      <Helmet>
        <title> Landscape designing services in Noida</title>
      </Helmet>

      <div className="flex flex-col  lg:justify-center container bg-white p-4 text-lg">
        <div className="flex md:flex-row lg:flex-row flex-col lg:justify-center lg:items-center bg-white gap-10 lg:pt-10 pt-2">
          <iframe
            src="https://www.youtube.com/embed/8seX_Ta8-6E?autoplay=1&mute=1"
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-[100%]"
            frameborder="0"
          ></iframe>
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-2 justify-end lg:pt-0 md:pt-24">
            <p className="text-black font-bold text-2xl md:text-3xl lg:text-4xl">
              Landscape design services in Noida
            </p>

            <p className="text-[#878787] text-lg ">
              Ashirwadum design & architects have significant hands-on experience in delivering
              urban planning projects as we have been serving this industry for a very long time. It
              includes Zonal planning, regional planning, Infrastructure planning, Master planning
              and special area development planning.
            </p>
            <p className="text-[#878787] text-lg ">
              Lastly, we ensure that our customers are delighted with our work.{' '}
            </p>
            <p className="text-[#878787] text-lg ">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex flex-wrap gap-2">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black">VIDEO | </p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center bg-white gap-5">
          <div className="grid  lg:grid-cols-4 md:grid-cols-4  grid-cols-2 container  lg:pt-14 md:pt-10 pt-5 gap-4">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic1.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic1.webp'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic2.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic2.webp'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic3.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic3.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic4.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic4.jpg'
                )
              }
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-5 container   lg:pt-20 md:pt-10 pt-5 ">
          <div className="flex flex-col  md:items-center ">
            <div className="flex flex-col lg:w-[20rem] md:w-[15%]  gap-10 ">
              <p className="md:text-4xl text-2xl font-bold text-black">
                Modern
                <br />
                Landscape Design
              </p>
            </div>
          </div>
          <div className="flex flex-col text-[#878787]  ">
            <p className="text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%] w-[75%]">
              Modern landscape design for farmhouses & villas Architecture + Interior Design
            </p>

            <p className="lg:w-[95%] md:w-[95%] md:text-xl text-lg">
              Step into a realm where modern design seamlessly blurs the boundaries between indoors
              and nature, ushering in a truly holistic living experience.
            </p>
            <p className="md:text-xl text-lg">
              Plants are selected for their structural qualities and year-round appeal rather than
              just for seasonal color, often arranged in thoughtful, deliberate groupings. Water
              features, like sleek ponds or fountains, are designed to be visually striking and
              soothing, while integrated lighting enhances the landscape’s aesthetic during the
              evening.
            </p>
          </div>
        </div>

        <div className="flex md:flex-row lg:flex-row flex-col lg:justify-center lg:items-center bg-white gap-10 pt-20">
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic5.jpg"
            alt=""
            className="lg:h-[50vh] lg:w-[70%] md:w-[100%] md:h-[40vh] h-[30vh] w-[100%]"
          />
          <div className="flex flex-col lg:w-[35rem] md:w-[20rem] gap-3 justify-end lg:pt-0 md:pt-24">
            <div className="text-black flex-col font-bold md:text-4xl text-2xl">
              <p>Morden landscape design </p>
            </div>

            <p className="text-[#878787] md:text-xl text-lg">
              All our professional landscape designers hold an experience of more than 30 years in
              different phrases of landscaping. Besides this, we completely understand that for a
              customer, his/her property is a very significant investment. Hence, we help our
              customers to accomplish all their dreams that they might be having for their property
              ranging from existing landscape construction to new construction landscapes for both
              commercial and residential projects. To start, we completely understand the ideas of
              our customers and further give suggestions from our end, and then we go ahead with a
              plan to execute the plan.
            </p>
            <p className="text-[#878787] md:text-xl text-lg">
              Visit Following Links for more information about this Project
            </p>
            <div className="flex">
              <Link to="https://www.youtube.com/@Ashirwadum">
                <p className="text-black">VIDEO |</p>
              </Link>
              <Link to="/blog">
                <p className="text-[#878787]">BLOGS</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center bg-white ">
          <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 container  gap-3  pt-14 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic6.jpeg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic6.jpeg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic7.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic7.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic8.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic8.webp'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic9.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic9.webp'
                )
              }
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-5 container   lg:pt-20 md:pt-10 pt-5 ">
          <div className="flex flex-col  md:items-center ">
            <div className="flex flex-col lg:w-[40%] md:w-[15rem] gap-10 ">
              <p className="md:text-4xl text-2xl font-bold text-black">Luxury Landspace Design </p>
            </div>
          </div>
          <div className="flex flex-col gap-2 text-[#878787] ">
            <div className="flex flex-col text-[#5d5c5b] text-xl lg:w-[95%] md:w-[95%]">
              <p>
                {' '}
                Design that reflects the client's lifestyle, such as outdoor sports courts, pools,
                or meditation gardens.
              </p>
              <p> Ashirwadum Architects & developers are leading architects for Landspace Design</p>
            </div>
            <p className="lg:w-[95%] md:w-[95%] md:p-2 md:text-xl text-lg ">
              Maintaining privacy without compromising on style is crucial. This can be achieved
              through custom-designed screens, walls, or dense hedges. Security features are often
              integrated seamlessly into the design, ensuring the safety of the space without
              disrupting its aesthetic. Lighting in a luxury landscape is carefully planned to
              create ambiance and highlight key features. This can include sophisticated outdoor
              lighting setups such as uplighting for trees, pathway lights, and strategically placed
              spotlights that enhance the nighttime beauty of the space.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 p-2 lg:pt-20 md:pt-10 pt-5">
          <div className="flex flex-col gap-3 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic10.jpg"
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
            />
            <p className=" font-bold text-xl text-black">ZANITH</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Zenith House’s clean lines, expansive windows, and harmonious use of materials create
              a sense of understated elegance.
            </p>
            <p className="text-[#878787]">
              {' '}
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong>VIDEO</strong>
              </Link>{' '}
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic11.jpg"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
            />
            <p className=" font-bold text-xl text-black ">TETRIS</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Tetris-Inspired House is a striking fusion of geometric shapes and vibrant colors.
            </p>
            <p className="text-black pt-4">
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong>VIDEO</strong>
              </Link>{' '}
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic12.avif"
              alt=""
              className="lg:h-[50vh] md:h-[40vh] h-[40vh]"
            />
            <p className=" font-bold text-xl text-black">OASIS</p>
            <p className="text-[#878787] md:text-xl text-lg">
              Oasis House is a celebration of modern aesthetics, exuding an unmistakable energy that
              encapsulates the spirit of the present age.
            </p>
            <p className="text-[#878787]">
              {' '}
              <Link to="https://www.youtube.com/@Ashirwadum">
                {' '}
                <strong>VIDEO</strong>
              </Link>{' '}
              |{' '}
              <Link to="/blog">
                {' '}
                <strong>BLOGS</strong>
              </Link>
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center bg-white ">
          <div className="grid md:grid-cols-4  grid-cols-2 container  gap-3  lg:pt-20 md:pt-10 pt-5 ">
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic13.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic13.webp'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic14.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic14.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic15.jpg"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic15.jpg'
                )
              }
            />
            <LazyLoadImage
              src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic16.webp"
              alt=""
              className="w-[100%] md:h-[17vh] h-[15vh] lg:h-[30vh] border-4 shadow-xl p-2"
              onClick={() =>
                openModal(
                  'https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic16.webp'
                )
              }
            />
          </div>
        </div>

        <div className="flex md:flex-row flex-col container  lg:gap-24  lg:pt-20 md:pt-10 pt-5">
          <div className="flex flex-col gap-3 text-black justify-center md:w-[35%]  w-[100%]">
            <p className=" md:text-4xl text-2xl font-bold">Ashirwadum Architects & developers</p>
            <p className=" md:text-4xl text-2xl font-bold">Modern Houses</p>
            <p className="text-xl text-[#5d5c5b]">
              Architecture + Interior Design Modern style house design for villas, bungalows &
              farm-houses.
            </p>
            <Link to="/about">
              <button className="border-4 border-black h-14 w-32 justify-center items-center text-center hover:bg-black hover:text-white  text-lg mt-10">
                ABOUT
              </button>
            </Link>
            <Link to="/housing-design">
              <button className="border-4 border-black h-14 w-48 mt-2 hover:bg-black hover:text-white text-lg">
                CLASSICAL HOUSES
              </button>
            </Link>
          </div>
          <LazyLoadImage
            src="https://ashirwadum.s3.ap-south-1.amazonaws.com/landscape-design/pic17.jpg"
            alt=""
            className="lg:w-[70rem] lg:h-[50vh] md:w-[50rem] md:h-[30vh] md:p-4 lg:p-0 w-[100%]"
          />
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage} />
    </div>
  )
}

export default Landscapedesign
